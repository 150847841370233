import React from "react";
import axios from "axios";
import { Pagination } from "@mui/material";
import { values } from "lodash";
import { renderResultNotFound } from "../../shared/Function/util/ResultUtil";

interface Props {
  totalCount: number;
}

interface State {
  faqList: [];
  faqListMore: [];
  searchValue: string;
  filterNoticeList: [];
  classification: Classification;
  totalCount: number;
  currentPage: number;
  selectedQid: string;
  isConcat: boolean;
}

enum Classification {
  All = "All",
  LoginSignUp = "LoginSignUp",
  GroupJoin = "GroupJoin",
  TourismContent = "TourismContent",
}

class FrequentlyQuestionsPage extends React.Component<Props, State> {
  static defaultProps: any = {};

  private pageLimit = 5;

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  constructor(props: any) {
    super(props);
    this.setClassification = this.setClassification.bind(this);
    this.findAllClassificationList = this.findAllClassificationList.bind(this);
    this.findSelectedClassificationList = this.findSelectedClassificationList.bind(this);
    this.showMore = this.showMore.bind(this);
  }

  //@ts-ignore
  state: State = {
    ...this.props,
    faqList: [],
    faqListMore: [],
    searchValue: "",
    filterNoticeList: [],
    classification: Classification.All,
    totalCount: 0,
    currentPage: 1,
    selectedQid: "",
    isConcat: false
  };

  async componentDidMount() {
    this.findAllClassificationList();
  }

  handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value }, () => {
      if (this.state.searchValue === "") {
        if (this.state.classification === Classification.All) {
          this.findAllClassificationList();
        } else {
          this.findSelectedClassificationList();
        }
      } else {
        this.findSearchKeyword();
      }
    });
  };

  setSearch = (event: any) => {
    this.setState({ searchValue: event.target.value });
  };

  setClassification = (newClassification: Classification) => {
    this.setState({ classification: newClassification, currentPage: 1 }, () => {
      if (newClassification === Classification.All) {
        this.findAllClassificationList();
      } else {
        this.findSelectedClassificationList();
      }
    });
  };

  async findAllClassificationList() {
    const { currentPage, isConcat } = this.state;
    await axios
      .post("/api/faq/aggregate/faq/faq/query/dynamic-multi", {
        responseName: "response",
        queryParams: {
          queryParams: [
            {
              fieldName: "exhibited",
              operation: "Equal",
              value: "Exhibited",
              connector: "And",
            },
            {
              fieldName: "portalScope",
              operation: "Equal",
              value: "true",
              connector: "And",
            },
            {
              fieldName: "id",
              operation: "Like",
              value: "%",
              connector: "End",
            },
          ],
        },
        offset: {
          offset: (currentPage - 1) * this.pageLimit,
          limit: this.pageLimit,
          totalCount: 0,
          totalCountRequested: true,
          sortDirection: "DESCENDING",
          sortingField: "registrationTime",
        },
      })
      .then((response) => {
        const faqList = response.data.queryResult;
        const totalCount = response.data.offset.totalCount;
        if (isConcat) {
          const newArray = this.state.faqList.concat(faqList);
          // @ts-ignore
          this.setState({faqList: newArray});
        } else {
          this.setState({ faqList, totalCount });
        }
      });
  }

  async findSelectedClassificationList() {
    const { currentPage, classification } = this.state;
    await axios
      .post("/api/faq/aggregate/faq/faq/query/dynamic-multi", {
        responseName: "response",
        queryParams: {
          queryParams: [
            {
              fieldName: "classification",
              operation: "Equal",
              value: classification,
              connector: "And",
            },
            {
              fieldName: "exhibited",
              operation: "Equal",
              value: "Exhibited",
              connector: "And",
            },
            {
              fieldName: "portalScope",
              operation: "Equal",
              value: "true",
              connector: "And",
            },
            {
              fieldName: "id",
              operation: "Like",
              value: "%",
              connector: "End",
            },
          ],
        },
        offset: {
          offset: (currentPage - 1) * this.pageLimit,
          limit: this.pageLimit,
          totalCount: 0,
          totalCountRequested: true,
          sortDirection: "DESCENDING",
          sortingField: "registrationTime",
        },
      })
      .then((response) => {
        const faqList = response.data.queryResult;
        const totalCount = response.data.offset.totalCount;
        this.setState({ faqList, totalCount });
      });
  }

  async findSearchKeyword() {
    const { currentPage, searchValue , isConcat } = this.state;
    await axios
      .post("/api/faq/aggregate/faq/faq/query/dynamic-multi", {
        responseName: "response",
        queryParams: {
          queryParams: [
            {
              fieldName: "exhibited",
              operation: "Equal",
              value: "Exhibited",
              connector: "And",
            },
            {
              fieldName: "portalScope",
              operation: "Equal",
              value: "true",
              connector: "And",
            },
            {
              fieldName: "title",
              operation: "Like",
              value: `%${searchValue}%`,
              connector: "Or",
            },
            {
              fieldName: "exhibited",
              operation: "Equal",
              value: "Exhibited",
              connector: "And",
            },
            {
              fieldName: "portalScope",
              operation: "Equal",
              value: "true",
              connector: "And",
            },
            {
              fieldName: "sentences",
              operation: "Like",
              value: `%${searchValue}%`,
              connector: "And",
            },
            {
              fieldName: "id",
              operation: "Like",
              value: "%",
              connector: "End",
            },
          ],
        },
        offset: {
          offset: (currentPage - 1) * this.pageLimit,
          limit: this.pageLimit,
          totalCount: 0,
          totalCountRequested: true,
          sortDirection: "DESCENDING",
          sortingField: "registrationTime",
        },
      })
      .then((response) => {
        const faqList = response.data.queryResult;
        const totalCount = response.data.offset.totalCount;
        if (isConcat) {
          const newArray = this.state.faqList.concat(faqList);
          // @ts-ignore
          this.setState({faqList: newArray});
        } else {
          this.setState({ faqList, totalCount });
        }
      });
  }


  showMore(event: React.ChangeEvent<unknown>){
    // 더보기를 누르면
    // 다음 페이지 데이터를 가져온다.
    this.setState({isConcat : true}, () => {
      const pageValue = this.state.currentPage + 1;
      this.handleChange(event, pageValue);
    })
    
//     const { currentPage } = this.state;
//     const { faqList } = this.state;
//     await axios
//       .post("/api/faq/aggregate/faq/faq/query/dynamic-multi", {
//         responseName: "response",
//         queryParams: {
//           queryParams: [
//             {
//               fieldName: "exhibited",
//               operation: "Equal",
//               value: "Exhibited",
//               connector: "And",
//             },
//             {
//               fieldName: "portalScope",
//               operation: "Equal",
//               value: "true",
//               connector: "And",
//             },
//             {
//               fieldName: "id",
//               operation: "Like",
//               value: "%",
//               connector: "End",
//             },
//           ],
//         },
//         offset: {
//           offset: (currentPage - 1) * this.pageLimit,
//           limit: this.pageLimit,
//           totalCount: 0,
//           totalCountRequested: true,
//           sortDirection: "DESCENDING",
//           sortingField: "registrationTime",
//         },
//       })
//       .then((response) => {
//         const faqListMore = response.data.queryResult;
//         this.state.faqList.concat(faqListMore);
//         this.setState({faqList: this.state.faqList});
//         // this.setState({faqList: [... faqList,...faqListMore]});
// // this.showMore();
//       });


  }
  setSelectedQid(selectedQid: any) {
    if (selectedQid === this.state.selectedQid) {
      this.setState({ selectedQid: "" });
    } else {
      this.setState({ selectedQid });
    }
  }

  onClickSearch() {
    this.setState(
      { currentPage: 1, classification: Classification.All },
      () => {
        this.findSearchKeyword();
      }
    );
  }

  render() {
    const {
      faqList,
      faqListMore,
      classification,
      totalCount,
      selectedQid,
      currentPage,
      searchValue,
    } = this.state;
    const pageCount = Math.ceil(totalCount / this.pageLimit);

    return (
      <>
        <div id="contents" className="faq">
          <div className="center nav_wrap">
            <ul className="nav">
              <li>
                <i className="icon home"></i>
              </li>
              <li>
                고객센터
                {/* <i className="icon down_circle"></i> */}
              </li>
              <li>
                <span>자주 묻는 질문</span>
                {/* <i className="icon down_circle"></i> */}
              </li>
            </ul>
          </div>
          <section className="board_area">
            <div className="center">
              <h1 className="board_tit">자주 묻는 질문</h1>

              <div className="board_wrap">
                <div className="b_search_box_wrap">
                  <div className="b_search_box">
                    <input
                      type="text"
                      placeholder="검색어 입력"
                      value={searchValue}
                      onChange={this.setSearch}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          this.onClickSearch();
                        }
                      }}
                    />
                    <button
                      className="st_point search_bt"
                      onClick={() => this.onClickSearch()}
                    >
                      검색
                    </button>
                  </div>
                </div>

                <div className="key_wrod_box">
                  <button
                    className={`${
                      classification === Classification.All
                        ? "st_point on"
                        : "st_basic"
                    }`}
                    onClick={() => this.setClassification(Classification.All)}
                  >
                    전체
                  </button>
                  <button
                    className={`st_basic ${
                      classification === Classification.LoginSignUp
                        ? "on"
                        : undefined
                    }`}
                    onClick={() =>
                      this.setClassification(Classification.LoginSignUp)
                    }
                  >
                    로그인・회원가입
                  </button>
                  <button
                    className={`st_basic ${
                      classification === Classification.GroupJoin
                        ? "on"
                        : undefined
                    }`}
                    onClick={() =>
                      this.setClassification(Classification.GroupJoin)
                    }
                  >
                    단체가입・단체관리
                  </button>
                  <button
                    className={`st_basic ${
                      classification === Classification.TourismContent
                        ? "on"
                        : undefined
                    }`}
                    onClick={() =>
                      this.setClassification(Classification.TourismContent)
                    }
                  >
                    관광정보
                  </button>
                </div>
                {faqList.length === 0 ? (
                  renderResultNotFound()
                ) : (
                  <>
                    <div className="pc_board">
                      <ul className="faq_list">
                        {faqList.map((faq: any) => (
                          <li
                            key={faq.id}
                            className={
                              this.state.selectedQid === faq.id
                                ? "on"
                                : undefined
                              }
                          >
                            <div className="q" draggable={true} onClick={() => this.setSelectedQid(faq.id)}>
                              <span className="icon">Q</span>
                              <h4 className="tit">
                                <span className="type">
                                  {faq.classification === "LoginSignUp"
                                    ? "로그인・회원가입"
                                    : faq.classification === "GroupJoin"
                                    ? "단체가입・단체관리"
                                    : faq.classification === "TourismContent"
                                    ? "관광정보"
                                    : faq.classification === ""}
                                </span>
                                <span className="txt">{faq.title}</span>
                              </h4>
                              <span className="arrow">
                                <img
                                  src="/rms-conlab/images/icon_board_arrow.png"
                                  alt="icon"
                                />
                              </span>
                            </div>
                            <div className="a">
                              <span className="icon">A</span>
                              <p dangerouslySetInnerHTML={{ __html: faq.sentences.replace(/\n/g, '<br>') }}></p>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div className="paging">
                        <ul>
                          <li className="on">
                            <Pagination
                              count={pageCount}
                              page={currentPage}
                              color="primary"
                              onChange={this.handleChange}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="m_board">
                      <ul className="faq_list">
                        {faqList.map((faq: any) => (
                          <li 
                            key={faq.id} 
                            className={
                              this.state.selectedQid === faq.id 
                                ? 'on' 
                                : undefined
                              } 
                            >
                            <div className="q" draggable={true} onClick={()=> this.setSelectedQid(faq.id)}>
                              <span className="icon">Q</span>
                              <h5 className="tit">
                                <span className="type">로그인・회원가입</span>
                                <span className="txt">{faq.title}</span>
                              </h5>
                              <span className="arrow">
                                <img
                                  src="/rms-conlab/images/icon_board_arrow.png"
                                  alt="icon"
                                />
                              </span>
                            </div>
                            <div className="a">
                              <span className="icon">A</span>
                              <p dangerouslySetInnerHTML={{ __html: faq.sentences.replace(/\n/g, '<br>') }}></p>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div className="center">
		      	{/* <Pagination count={pageCount} color="primary" onChange={this.handleChange}/> */}
            {currentPage !== pageCount && (
            <div className="center">
              <button className="st_line4 more_board_bt" onClick={this.showMore}>더보기<img src="./images/icon_add_min.png" alt="icon_add" /></button>
            </div>)}
		      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default FrequentlyQuestionsPage;

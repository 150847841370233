import React from "react";
import MainPage from "./conlab/MainPage";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import IntroductionPage from "./pages/introduce/IntroductionPage";
import Layout from "./components/Layout";
import ContentByTypePage from "./pages/tourism-contents/ContentByTypePage";
import ContentStatisticsPage from "./pages/tourism-statistics/ContentStatisticsPage";
import OnlineServicePage from "./pages/online-service/OnlineServicePage";
import OfflineServicePage from "./pages/offline-service/OfflineServicePage";
import RecommendedContentPage from "./pages/tourism-recommend/RecommendedContentPage";
import UserGuidePage from "./pages/guide/UserGuidePage";
import AnnouncementMainPage from "./pages/announcement/AnnouncementMainPage";
import FrequentlyQuestionsPage from "./pages/questions/FrequentlyQuestionsPage";
import AnnouncementDetailPage from "./pages/announcement-detail/AnnouncementDetailPage";
import SearchMainPage from "./pages/search/SearchMainPage";
import PolicyView from "./OrgProposalForm/view/PolicyView";
import ByTop from "./pages/sign-up/by-top/ByTop";
import { Helmet } from "react-helmet-async";
import TermsOfUse from "./pages/terms-of-use/TermsOfUse";
import Email from "./pages/terms-of-email/Email";
import Custom404 from "./pages/404.page";
import Custom500 from "./pages/500.page";
import CommonContext from "./context/CommonContext";
import axios from "axios";
import CitizenUser from "./model/User/CitizenUser";
import ActorIdentity from "./model/User/ActorIdentity";
import TopLoginModal from "./conlab/TopLoginModal";
import SsoFail from "./conlab/SsoFail";
import jwt from "jsonwebtoken";
import { checkKtoTop, checkTop, expiredSession, getCitizen, getSsoId, removeKtoTop, removeOrgType, removeSessionSsoId, removeSessionTopPopup, removeTopClear, setTopClear, settingLoginStorage, topSessionRemove, topSessionclear } from "./conlab";
import JoinPage from "./pages/join/JoinPage";
import Cineroom from "./comp/api/metro/cineroom/api-model/Cineroom";
import Cast from "./comp/api/stage/Cast";
import Actor from "./model/User/Actor";
import DockNode from "./shared/api-model/DockNode";
import Troupe from "./shared/api-model/Troupe";
import { checkCitizen, checkCitizenWithdrawal, checkProxy, createTopCitizen, findCitizenByCitizenId, onClickTopLogin, setLoginSession, toAudience } from "./shared/Function/checkpoint/Function";
import ReactGA from "react-ga4"
import CopyrightPolicyPage from "./pages/copyright-policy/CopyrightPolicyPage";
import { TopSns } from "./model/type/TopSnsType";
import { findAudience, findCitizenInvitationBySsoId, modifyLatestCineroom } from "./shared/Function/metro/Function";
import { closureCheck } from "./shared/Function/onboarding/Function";
import { findOrgBySsoId } from "./shared/Function/onboarding/OrgProposalQueryFlow";
import { defaultCast, findActorIdentity, modifyLatestCast } from "./shared/Function/stage/Function";
import CommonModal from "./conlab/CommonModal";
import ContentsDetailPage from "./conlab/main-theme/ContentsDetailPage";

interface Props {}

interface State {
  isLogin: boolean;
  isOpenLoginModal: boolean;
  tokenExpTime: number | undefined;
  dockNodeFetched: boolean;
  citizenUser: CitizenUser;
  actorIdentity: ActorIdentity;
  accessibleCinerooms: DockNode[];
  accessibleCasts: DockNode[];
  retryCount: number;
  isCheckedProfile: boolean;
  initialized: boolean;
  loginSnsKey: TopSns | null;
  openModal: boolean;
  modalText: string;
  loginFailText: string;
  open: undefined | 'open';
  openTopLoginModal: boolean;
  topLoginFail: boolean;
  gaTagLoad: boolean;
}

class KtoConlab extends React.Component<Props, State> {
  private isLoginStorageKey = "nara.isLogin";
  private citizenUserStorageKey = "nara.citizenUser";
  private accessTokenStorageKey = "nara.accessToken";
  private refreshTokenStorageKey = "nara.refreshToken";
  private audienceStorageKey = "nara.audience";
  private cineroomIdsStorageKey = "nara.cineroomIds";
  private casOfficeIdsStorageKey = "nara.casOfficeIds";
  private actorStorageKey = "nara.actor";
  private actorIdentityStorageKey = "nara.actorIdentity";
  private isCheckedProfileStorageKey = "nara.isCheckedProfile";
  private loginSnsKey = "nara.topSns";

  constructor(props: any) {
    super(props);
    this.handleLoginState = this.handleLoginState.bind(this);
    this.openLoginModal = this.openLoginModal.bind(this);
    this.closeLoginModal = this.closeLoginModal.bind(this);
    this.getToken = this.getToken.bind(this);
    this.setTokenExpTime = this.setTokenExpTime.bind(this);
    this.onExpired = this.onExpired.bind(this);
    this.refresh = this.refresh.bind(this);
    this.verifyResponse = this.verifyResponse.bind(this);
    this.setAuth = this.setAuth.bind(this);
    this.setToken = this.setToken.bind(this);
    this.setCitizenUser = this.setCitizenUser.bind(this);
    this.setCineroomIds = this.setCineroomIds.bind(this);
    this.setCasOfficeIds = this.setCasOfficeIds.bind(this);
    this.setLogin = this.setLogin.bind(this);
    this.getCineroomIds = this.getCineroomIds.bind(this);
    this.getAudienceId = this.getAudienceId.bind(this);
    this.getTroupe = this.getTroupe.bind(this);
    this.fetchDockNodes = this.fetchDockNodes.bind(this);
    this.settleTopTokens = this.settleTopTokens.bind(this);
    this.refreshTopStorage = this.refreshTopStorage.bind(this);
    this.handleCheckProfileState = this.handleCheckProfileState.bind(this);
    this.getLogin = this.getLogin.bind(this);
    this.registerUserAccessLog = this.registerUserAccessLog.bind(this);

    this.closeModal = this.closeModal.bind(this);
    this.init = this.init.bind(this);
    this.closeTopLoginModal = this.closeTopLoginModal.bind(this);
    this.onClickTopLoginModalOpen = this.onClickTopLoginModalOpen.bind(this);
    this.checkTop = this.checkTop.bind(this);
    this.open = this.open.bind(this);
    this.openModal = this.openModal.bind(this);
    this.topLoginCloseModal = this.topLoginCloseModal.bind(this);
    this.onClickTopLogin = this.onClickTopLogin.bind(this);
    this.checkCitizenFail = this.checkCitizenFail.bind(this);
    this.closeLoginFailModal = this.closeLoginFailModal.bind(this);

    this.checkProxy = this.checkProxy.bind(this);
  }

  state: State = {
    isLogin: false,
    isOpenLoginModal: false,
    tokenExpTime: undefined,
    dockNodeFetched: false,
    citizenUser: CitizenUser.new(),
    actorIdentity: ActorIdentity.new(),
    accessibleCinerooms: [],
    accessibleCasts: [],
    retryCount: 0,
    isCheckedProfile: false,
    initialized: false,
    loginSnsKey: null,
    openModal: false,
    modalText: '',
    loginFailText: '',
    open: undefined,
    openTopLoginModal: false,
    topLoginFail: false,
    gaTagLoad: false
  };

  componentDidMount(): void {
    // 내부망 외부망 체크
    this.checkProxy();

    const path = window.location.pathname;
    if (path !== '/rms-conlab/join' && path !== '/rms-conlab/sign-up/by-top') {
        if (!checkTop()) {
          removeSessionSsoId();
        }
        removeOrgType();
    }
    
    // accessToken이 만료되면 session expire
    const accessToken = window.localStorage.getItem(this.accessTokenStorageKey);
    const refreshToken = window.localStorage.getItem(
      this.refreshTokenStorageKey
    );
    if (accessToken) {
      const jwtToken = jwt.decode(accessToken as string) as jwt.JwtPayload;
      const exp = (jwtToken.exp as number) * 1000;
      const now = new Date().getTime();

      const expired = exp - now < 0;

      if (expired) {
        console.warn(`token expired`);
        this.onExpired();
      }
    }

    this.settleTopTokens();
    const isLogin = this.getLogin();
    if (isLogin) {
      this.registerUserAccessLog();
      this.init();
    } else {
      this.checkTop();
    }
    // if (!window.location.href.includes("localhost")) {
    //   // @ts-ignore
    //   ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    // }
    
    this.setState({initialized: true});
  }

  async checkProxy() {
    const data = await checkProxy();
    if (!data) {
      if (data === false) {
        // 외부망
        this.setState({ gaTagLoad: true });
      }
    }
  }

  closeModal() {
    this.setState({ openModal: false });
    removeSessionTopPopup();
    removeSessionSsoId();
    removeKtoTop();
  }

  async init() {
    // const { refreshTopStorage, handleCheckProfileState } = this.context;
    const citizen = getCitizen();
    // const { eventFetchDockNodes } = this.props; - > this.fetchDockNodes
    if (!citizen) {
      return;
    }
    const citizenUser = JSON.parse(citizen);
    if (citizenUser.citizenId){
      const citizenId = citizenUser.citizenId;
      const audience = await findAudience(citizenId);
      const audienceID = audience.queryResult.id;
      const cineroomId = audience.queryResult.cineroomId;
      
      // 탈퇴 회원
      const withdrawalCheck = await checkCitizenWithdrawal(citizenId, cineroomId);
      if (withdrawalCheck !== 'success') {
        topSessionclear();
        this.refreshTopStorage();
        this.handleCheckProfileState(false);
        if (withdrawalCheck === 'profile dormant') {
          // 단체장 탈퇴 or 단체멤버 탈퇴
          this.openModal('탈퇴 처리된 회원입니다.\n서비스 이용을 원하실 경우\n새로운 이메일로\n신규 회원가입을 진행해주세요.');
          return;
        } else if (withdrawalCheck === 'NoSuchElementCineroom') {
          // 단체장이 탈퇴해서 단체가 없어졌을 때
          this.openModal('가입된 단체가 존재하지 않습니다.');
          return;
        }
      }
      
      if (cineroomId !== '1:1:a') { // 개인회원 임시 처리
        const closure = await closureCheck(cineroomId);
        if (closure.groupState && closure.groupState !== 'Active') {
          topSessionclear();
          this.refreshTopStorage();
          this.handleCheckProfileState(false);
          if (closure.groupState === 'ClosureRequested') {
            this.openModal('단체 폐쇄 중입니다.');
            return;
          } else {  // Closed
            this.openModal('가입된 단체가 존재하지 않습니다.');
            return;
          }
        }
      }
      
      const cast = await defaultCast(audienceID);
      const modifyCineroom = await modifyLatestCineroom(citizenId, cineroomId);
      // const cast = await findCastByBasePathAndStageChart('/conlab', citizenUser.cineroomIds[0]);
      const modifyCast = await modifyLatestCast(audienceID, cast.queryResult.id);
      const actorIdentity = await findActorIdentity(audienceID, cineroomId, '/rms-conlab');
      settingLoginStorage(audienceID, actorIdentity.queryResult);
      this.handleCheckProfileState(true);
      const audienceId = this.getAudienceId();
      this.fetchDockNodes(audienceId);
    }
  }

  closeTopLoginModal() {
    this.setState({ open: undefined });
    document.body.style.cssText = "overflow: unset";
  }

  topLoginCloseModal() {
    topSessionclear();
    this.setState({ openTopLoginModal: false });
  }

  onClickTopLoginModalOpen() {
    this.setState({ open: undefined });
    document.body.style.cssText = "overflow: unset";
    removeSessionTopPopup();
    this.setState({ openTopLoginModal: true });
  }

  async checkTop() {
    const searchParams = new URLSearchParams(window.location.search);
    const errorCode = searchParams.get("errorCode");
    const errorMsg = searchParams.get("errorMsg");

    const ssoId = getSsoId();
    const topClear = window.sessionStorage.getItem('topClear');
    const topPopup = checkTop();
    // if (!topPopup && ssoId) {
    //   removeSessionSsoId();
    // }
    if (topClear) {
      topSessionRemove();
    }
    if (topPopup === "true") {
      // orgProposal check
      const org = await findOrgBySsoId(ssoId);
      if (org !== '') {
        this.setState({ openModal: true, modalText: '회원 가입 승인 전입니다. \n 관리자의 승인 이후 서비스를 이용하실 수 있습니다.' });
        return;
      }
      // citizenInvitation check
      const res = await findCitizenInvitationBySsoId(ssoId);
      if (res !== '') {
        this.setState({ openModal: true, modalText: '회원 가입 승인 전입니다. \n 관리자의 승인 이후 서비스를 이용하실 수 있습니다.' });
        return;
      }
      // top 로그인
      this.open();
    } else if (topPopup === "fail") {
      const failText = "투어원패스 연동에 실패했습니다.";
      const modalText = errorCode
        ? failText + `\n(오류코드: ${errorCode})`
        : failText;
      this.openModal(modalText);
    } else if (checkKtoTop()) {
      let failText = "관광공사 SSO 연동에 실패했습니다.";
      if (errorCode && errorMsg) {
        failText += `\n(오류코드: ${errorCode}, 오류메세지: ${errorMsg})`;
      } else if (errorCode) {
        failText += `\n(오류코드: ${errorCode})`;
      }
      this.openModal(failText);
    }
  }

  open() {
    this.setState({ open: "open" });
    document.body.style.cssText = "overflow: hidden !important";
  }

  openModal(txt: string) {
    this.setState({ openModal: true, modalText: txt });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.state.gaTagLoad && !prevState.gaTagLoad) {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-344404408';
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        (window as any).dataLayer = (window as any).dataLayer || [];
        
        function gtag(...args: any[]) {
          (window as any).dataLayer.push(args);
        }
        (window as any).gtag = gtag;

        gtag('js', new Date());
        gtag('config', 'AW-344404408');
      };
    }
    if (prevState.initialized !== this.state.initialized && this.state.initialized) {
        ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
    }
  }

  async registerUserAccessLog() {
    // @ts-ignore
    const actorIdentityToken: ActorIdentity = JSON.parse(window.localStorage.getItem(this.actorIdentityStorageKey));
    // @ts-ignore
    const actorToken: {id: string, name: string, usid: string} = JSON.parse(window.localStorage.getItem(this.actorStorageKey));

    if (Object.keys(actorToken).length === 0) {
      return;
    }

    await axios.post(
      "/api/keyword/flow/log-flow/command/register-user-access-log",
      {
        responseName: "response",
        actorId: actorToken.name,
        email: actorIdentityToken.email,
        url: window.location.href,
      }
    ).catch((error) => {
      console.log(error);
    });
  }

  setTokenExpTime() {
    const accessToken = window.localStorage.getItem(this.accessTokenStorageKey);
    const refreshToken = window.localStorage.getItem(
      this.refreshTokenStorageKey
    );
    // refreshToken이 만료되면 expire
    if (refreshToken) {
      const refreshJwt = jwt.decode(refreshToken as string) as jwt.JwtPayload;
      const exp = (refreshJwt.exp as number) * 1000;
      const now = new Date().getTime();

      const expired = exp - now < 0;

      const expDate = new Date(exp);
      const expFormatted = expDate.toISOString().slice(0, 19).replace("T", " ");
      const nowDate = new Date(now);
      const nowFormatted = nowDate.toISOString().slice(0, 19).replace("T", " ");

      if (expired) {
        this.onExpired();
      }
    }
    if (accessToken) {
      const jwtToken = jwt.decode(accessToken as string) as jwt.JwtPayload;

      const tokenExpTime = (jwtToken.exp as number) * 1000;
      const currentTime = new Date().getTime();
      const remainTime = (tokenExpTime - currentTime) / 60000;

      console.log('token remainTime: ', remainTime);
      if (remainTime > 0 && remainTime < 10) {
        // access_token의 유효 시간이 10분 이내로 남았을 때 동작이 감지되면(새로고침, 버튼 클릭 등) access_token 및 refres_token 재발급
        this.refresh().then(this.setTokenExpTime);
      }
      this.setState({ tokenExpTime });
    } else {
      this.setState({ tokenExpTime: 0 });
    }
  }

  async refresh(rToken?: string) {
    console.warn('refresh token');
    const refToken = rToken || this.getToken().refresh || "";

    const postData = new FormData();

    postData.append("grant_type", "refresh_token");
    postData.append("scope", "citizen");
    postData.append("refresh_token", refToken);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic " + btoa("nara:narasecret"),
      },
      noAuth: true,
      noCatch: true,
    };

    const response = await axios.post(
      "/api/checkpoint/oauth/token",
      postData,
      config
    );

    if (!this.verifyResponse(response)) {
      return response;
    }

    const responseData = response.data;

    const { access_token, refresh_token, loginId, displayName, cineroomIds, citizenId, casOfficeIds } = responseData;

    this.setAuth(
      access_token,
      refresh_token,
      new CitizenUser(loginId, displayName, citizenId),
      cineroomIds,
      casOfficeIds
    );

    return response;
  }

  private verifyResponse(response: any): boolean {
    if (response.status !== 200) {
      console.warn(`[ checkpoint.citizenuser ] Failed to login.`);
      return false;
    }
    const accessToken = response.data.access_token;
    const refreshToken = response.data.refresh_token;

    if (!(accessToken && refreshToken)) {
      throw new Error(
        `Failed to login. Token is invalid -> { access: ${accessToken}, refresh: ${refreshToken}}`
      );
    }
    return true;
  }

  setAuth(
    accessToken: string,
    refreshToken: string,
    citizenUser: CitizenUser,
    cineroomIds: string[],
    casOfficeIds: string[]
  ) {
    //
    this.setToken(accessToken, refreshToken);
    this.setCitizenUser(citizenUser);
    this.setCineroomIds(cineroomIds);
    this.setCasOfficeIds(cineroomIds);
    this.setCasOfficeIds(casOfficeIds);
    this.setLogin();
  }

  setToken(accesToken: string, refreshToken: string) {
    window.localStorage.setItem(this.accessTokenStorageKey, accesToken);
    window.localStorage.setItem(this.refreshTokenStorageKey, refreshToken);
  }

  setCineroomIds(cineroomIds: string[]) {
    const arrString = JSON.stringify(cineroomIds);
    window.localStorage.setItem(this.cineroomIdsStorageKey, arrString);
  }

  getCineroomIds() {
    // @ts-ignore
    const cineroomIds = JSON.parse(window.localStorage.getItem(this.cineroomIdsStorageKey));
    return cineroomIds;
  }

  getAudienceId() {
    let retAudience: {
      id: string;
      name: string;
      usid: string;
    } = toAudience("");

    // @ts-ignore
    const audienceId = JSON.parse(window.localStorage.getItem(this.audienceStorageKey));
    if (audienceId) {
      if (Object.keys(audienceId).length > 0) {
        retAudience = audienceId;
      }
    }

    return retAudience.name;
  }

  setCasOfficeIds(casOfficeIds: string[]) {
    const arrString = JSON.stringify(casOfficeIds);
    window.localStorage.setItem(this.casOfficeIdsStorageKey, arrString);
  }

  setLogin() {
    window.localStorage.setItem(this.isLoginStorageKey, "true");
  }

  getLogin() {
    // @ts-ignore
    return JSON.parse(window.localStorage.getItem(this.isLoginStorageKey));
  }

  setCitizenUser(citizenUser: CitizenUser) {
    const jsonString = JSON.stringify(citizenUser);
    window.localStorage.setItem(this.citizenUserStorageKey, jsonString);
  }

  getToken(): { access: string | null; refresh: string | null } {
    const accessToken = window.localStorage.getItem(this.accessTokenStorageKey);
    const refreshToken = window.localStorage.getItem(
      this.refreshTokenStorageKey
    );

    return {
      access: accessToken,
      refresh: refreshToken,
    };
  }

  getTroupe(troupes: Troupe[], id: string) {
    return troupes.find((troupe) => troupe.id === id) as Troupe;
  }

  onExpired() {
    //
    console.warn("[ Bay ] onExpired...");
    topSessionclear();
    // 세션 삭제 후 로그인 패널 변경을 위한 윈도우 리로드
    window.location.reload();
    removeKtoTop();
    // this.setState({ openModal: true, modalText: expiredSession });
    // this.routeToLogin();
  }

  async fetchDockNodes(
    audienceId: string
  ): Promise<{ accessibleCinerooms: Cineroom[]; accessibleCasts: Cast[] }> {
    const accessibleCineroomIds = JSON.stringify(this.getCineroomIds());
    const RetryLimit = 10;

    const accessibleCinerooms: Cineroom[] = [];
    const accessibleCasts: Cast[] = [];

    await axios
      .post("/api/metro/flow/tenant-context-flow/query/dynamic-multi", {
        responseName: "response",
        queryParams: {
          queryParams: [
            {
              fieldName: "id",
              operation: "In",
              value: accessibleCineroomIds,
              connector: "End",
            },
          ],
        },
        offset: {
          offset: 0,
          limit: 10000,
          totalCount: 0,
          totalCountRequested: true,
          sortDirection: "ASCENDING",
          sortingField: "id",
        },
      })
      .then((response) => {
        for (let i = 0; i < response.data.queryResult.length; i++) {
          accessibleCinerooms.push(response.data.queryResult[i]);
        }
      });

    const actors = await axios
      .post("/api/stage/flow/app-flow/query/actor/dynamic-multi", {
        responseName: "response",
        queryParams: {
          queryParams: [
            {
              fieldName: "audienceId",
              operation: "Equal",
              value: audienceId,
              connector: "End",
            },
          ],
        },
        offset: {
          offset: 0,
          limit: 10000,
          totalCount: 0,
          totalCountRequested: true,
          sortDirection: "ASCENDING",
          sortingField: "audienceId",
        },
      })
      .then((response) => {
        return response.data.queryResult;
      });

    const castValue = actors ? actors.map((actor: Actor) => actor.castId) : [];
    const casts = await axios
      .post("/api/stage/flow/app-flow/query/cast/dynamic-multi", {
        responseName: "response",
        queryParams: {
          queryParams: [
            {
              fieldName: "id",
              operation: "In",
              value: JSON.stringify(castValue),
              connector: "End",
            },
          ],
        },
        offset: {
          offset: 0,
          limit: 10000,
          totalCount: 0,
          totalCountRequested: true,
          sortDirection: "ASCENDING",
          sortingField: "id",
        },
      })
      .then((response) => {
        return response.data.queryResult;
      });

    const troupesValue = casts ? casts.map((cast: Cast) => cast.troupeId) : [];
    const troupes = await axios
      .post("/api/stage/flow/app-flow/query/troupe/dynamic-multi", {
        responseName: "response",
        queryParams: {
          queryParams: [
            {
              fieldName: "id",
              operation: "In",
              value: JSON.stringify(troupesValue),
              connector: "End",
            },
          ],
        },
        offset: {
          offset: 0,
          limit: 10000,
          totalCount: 0,
          totalCountRequested: true,
          sortDirection: "ASCENDING",
          sortingField: "id",
        },
      })
      .then((response) => {
        return response.data.queryResult;
      });

    accessibleCasts.push(...casts);

    const { retryCount } = this.state;

    if (
      accessibleCinerooms.length * accessibleCasts.length === 0 &&
      retryCount < RetryLimit
    ) {
      this.setState({ retryCount: retryCount + 1 });
      return this.fetchDockNodes(audienceId);
    } else {
      // onClickCineroomDockNode 메소드 필요 없음
      // this.setState({
      //   accessibleCinerooms: accessibleCinerooms.map(cineroom => DockNode.byCineroom(cineroom, this.onClickCineroomDockNode)),
      //   accessibleCasts: accessibleCasts.map(cast => DockNode.byCast(this.getTroupe(troupes, cast.troupeId), cast, this.onClickStageDockNode)),
      //   dockNodeFetched: true,
      // });
      this.setState({
        accessibleCinerooms: accessibleCinerooms.map((cineroom) =>
          DockNode.byCineroom(cineroom)
        ),
        accessibleCasts: accessibleCasts.map((cast) =>
          DockNode.byCast(this.getTroupe(troupes, cast.troupeId), cast)
        ),
        dockNodeFetched: true,
      });

      return {
        accessibleCinerooms,
        accessibleCasts,
      };
    }
  }

  async onClickTopLogin(event: any, id: string, pw: string) {
    event.preventDefault();
    const ssoId = getSsoId();
    if (!ssoId) {
      this.setState({
        topLoginFail: true,
        loginFailText: "잘못된 접근입니다.",
        openTopLoginModal: false,
      });
      return;
    }
    if (id === "" || pw === "") {
      this.setState({
        topLoginFail: true,
        loginFailText: "아이디 또는 비밀번호가 잘못 입력되었습니다.",
        openTopLoginModal: false,
      });
      return;
    }
    // 이미 계정이 연동되어있는지 check
    try {
      const citizenCheck = await checkCitizen(id, pw);
      const citizenId = citizenCheck.entityIds[0];
      if (citizenCheck.entityIds && citizenId) {
        const topCitizen = await findCitizenByCitizenId(citizenId);
        if (topCitizen === 'TRUE') {
          this.checkCitizenFail("이미 사용 중인 SNS 계정으로 등록이 불가능합니다.");
          return;
        }
      } else if (citizenCheck.failureMessage) {
        if (citizenCheck.failureMessage.exceptionName === 'NoSuchElementException') {
          this.checkCitizenFail("아이디 또는 비밀번호가 잘못 입력되었습니다.");
          return;
        }
      }
      // 탈퇴 check
      const audience = await findAudience(citizenId);
      const cineroomId = audience.queryResult.cineroomId;
      const withdrawalCheck = await checkCitizenWithdrawal(citizenId, cineroomId);
      if (withdrawalCheck !== 'success') {
        topSessionclear();
        if (withdrawalCheck === 'profile dormant') {
          // 단체장 탈퇴 or 단체멤버 탈퇴
          this.openModal('탈퇴 처리된 회원입니다.\n서비스 이용을 원하실 경우\n새로운 이메일로\n신규 회원가입을 진행해주세요.');
          return;
        } else if (withdrawalCheck === 'NoSuchElementCineroom') {
          // 단체장이 탈퇴해서 단체가 없어졌을 때
          this.checkCitizenFail('가입된 단체가 존재하지 않습니다.');
          return;
        }
      }
      if (cineroomId !== '1:1:a') { // 개인회원 임시 처리
        const closure = await closureCheck(cineroomId);
        if (closure.groupState && closure.groupState !== 'Active') {
          topSessionclear();
          if (closure.groupState === 'ClosureRequested') {
            this.checkCitizenFail('단체 폐쇄 중입니다.');
            return;
          } else {  // Closed
            this.checkCitizenFail('가입된 단체가 존재하지 않습니다.');
            return;
          }
        }
      }
    } catch(e) {
      this.checkCitizenFail("아이디 또는 비밀번호가 잘못 입력되었습니다.");
      return;
    }
    // 계정연동
    try {
      const response = await onClickTopLogin(id, pw, ssoId);
      if (response.data && response.data.access_token && response.data.refresh_token && response.data.displayName) {
        const { access_token, refresh_token, displayName, cineroomIds, citizenId, casOfficeIds, strategyResult, topSns } = response.data;
        setLoginSession(access_token, refresh_token, displayName, cineroomIds, citizenId, casOfficeIds, strategyResult, topSns, id);
        await createTopCitizen(citizenId, ssoId);
        removeSessionSsoId();
        removeTopClear();
        this.setState({ topLoginFail: false, loginFailText: "" });
        location.reload();
      } else if (response.data.failureMessage?.exceptionMessage.includes('No Citizen User with id, domainUrl')) {
        this.checkCitizenFail("아이디 또는 비밀번호가 잘못 입력되었습니다.");
        return;
      } else {
        this.checkCitizenFail("올바르지 않은 접근입니다.");
        return;
      }
    } catch(error) {
      this.checkCitizenFail("올바르지 않은 접근입니다.");
      return;
    }
  }

  checkCitizenFail(txt: string) {
    this.setState({
      topLoginFail: true,
      loginFailText: txt,
      openTopLoginModal: false,
    });
    setTopClear();
  }

  routeToLogin() {
    const domain = window.location.host;
    topSessionclear();
    // window.location.replace(`http://${domain}/rms-conlab`);
  }

  handleLoginState(value: boolean) {
    this.setState({ isLogin: value });
  }

  handleCheckProfileState(value: boolean) {
    this.setState({ isCheckedProfile: value });
    const newValue = JSON.stringify(value);
    window.localStorage.setItem(this.isCheckedProfileStorageKey, newValue);
  }

  openLoginModal() {
    this.setState({ isOpenLoginModal: true });
  }

  closeLoginModal() {
    this.setState({ isOpenLoginModal: false });
  }

  closeLoginFailModal() {
    const { loginFailText } = this.state;
    if (loginFailText === "잘못된 접근입니다.") {
      this.setState({ topLoginFail: false, loginFailText: "" });
    } else {
      this.setState({
        topLoginFail: false,
        loginFailText: "",
        openTopLoginModal: true,
      });
    }
  }

  settleTopTokens() {
    // @ts-ignore
    const isLoginToken: boolean = JSON.parse(window.localStorage.getItem(this.isLoginStorageKey));
    // @ts-ignore
    const citizenUserToken: CitizenUser = JSON.parse(window.localStorage.getItem(this.citizenUserStorageKey));
    // @ts-ignore
    const actorIdentityToken: ActorIdentity = JSON.parse(window.localStorage.getItem(this.actorIdentityStorageKey));
    const audienceIdToken: string = this.getAudienceId();
    const loginSns = window.localStorage.getItem(this.loginSnsKey);
    this.setState(
      {
        isLogin: isLoginToken,
        citizenUser: citizenUserToken,
        actorIdentity: actorIdentityToken,
        // @ts-ignore
        loginSnsKey: loginSns
      },
      () => {
        if (isLoginToken && audienceIdToken) {
          this.setTokenExpTime();
          this.fetchDockNodes(audienceIdToken);
        }
      }
    );
  }

  refreshTopStorage() {
    this.settleTopTokens();
  }

  render() {
    const hostName = window.location.hostname;
    const GA_TRACKING_ID =
      hostName !== "conlab.visitkorea.or.kr" ? "G-8FHPVVV8EX" : "G-R8J5S0JRSE";
    const {
      isLogin,
      isOpenLoginModal,
      citizenUser,
      actorIdentity,
      accessibleCinerooms,
      accessibleCasts,
      isCheckedProfile,
      loginSnsKey,
      openModal,
      modalText,
      open,
      openTopLoginModal,
      topLoginFail,
      loginFailText
    } = this.state;
    const {
      handleLoginState,
      openLoginModal,
      closeLoginModal,
      refreshTopStorage,
      handleCheckProfileState,
      closeModal,
      closeTopLoginModal,
      onClickTopLoginModalOpen,
      onClickTopLogin,
      closeLoginFailModal,
      topLoginCloseModal
    } = this;

    return (
      <>
        <BrowserRouter>
          <CommonContext.Provider
            value={{
              isLogin,
              isOpenLoginModal,
              citizenUser,
              actorIdentity,
              handleLoginState,
              openLoginModal,
              closeLoginModal,
              refreshTopStorage,
              handleCheckProfileState,
              isCheckedProfile,
              accessibleCinerooms,
              accessibleCasts,
              loginSnsKey,
              openModal,
              modalText,
              closeModal,
              open,
              closeTopLoginModal,
              onClickTopLoginModalOpen,
              openTopLoginModal,
              topLoginFail,
              onClickTopLogin,
              loginFailText,
              closeLoginFailModal,
              topLoginCloseModal
            }}
          >
            <Layout>
              <Routes>
                <Route path="/" element={<MainPage />} />{" "}
                {/* redirect 요소들을 root 에서 처리하는 비즈니스가 들이 존재함 */}
                <Route
                  path="/rms-conlab"
                  element={
                    <MainPage
                      eventFetchDockNodes={this.fetchDockNodes}
                      basePath={"/rms-conlab"}
                    />
                  }
                />
                <Route path="/rms-conlab/error/sso" element={<SsoFail />} />{" "}
                {/* sso fail */}
                <Route
                  path="/rms-conlab/introduce"
                  element={<IntroductionPage />}
                />
                <Route
                  path="/rms-conlab/copyright-policy"
                  element={<CopyrightPolicyPage />}
                />
                <Route
                  path="/rms-conlab/tourism-contents"
                  element={<ContentByTypePage />}
                />
                <Route
                  path="/rms-conlab/tourism-statistics"
                  element={<ContentStatisticsPage />}
                />
                <Route
                  path="/rms-conlab/online-service"
                  element={<OnlineServicePage />}
                />
                <Route
                  path="/rms-conlab/offline-service"
                  element={<OfflineServicePage />}
                />
                <Route
                  path="/rms-conlab/tourism-recommend"
                  element={<RecommendedContentPage />}
                />
                <Route path="/rms-conlab/guide" element={<UserGuidePage />} />
                <Route
                  path="/rms-conlab/announcement"
                  element={<AnnouncementMainPage />}
                />
                <Route
                  path="/rms-conlab/announcement-detail"
                  element={<AnnouncementDetailPage />}
                />
                <Route
                  path="/rms-conlab/questions"
                  element={<FrequentlyQuestionsPage />}
                />
                <Route path="/rms-conlab/search" element={<SearchMainPage />} />
                <Route path="/rms-conlab/policy" element={<PolicyView />} />
                {/* <Route path='/mail-auth-view' element={<MailAuthFormView/>}/> */}
                <Route path="/rms-conlab/sign-up/by-top" element={<ByTop />} />{" "}
                {/* 회원가입 url mapping */}
                <Route
                  path="/rms-conlab/terms-of-use"
                  element={<TermsOfUse />}
                />
                <Route path="/rms-conlab/terms-of-email" element={<Email />} />
                <Route path="/rms-conlab/custom404" element={<Custom404 />} />
                <Route path="/rms-conlab/custom500" element={<Custom500 />} />
                <Route path="/rms-conlab/join" element={<JoinPage />} />
                
                <Route path="/rms-conlab/detail" element={<ContentsDetailPage />} />
              </Routes>
            </Layout>
          </CommonContext.Provider>
        </BrowserRouter>
      </>
    );
  }
}

export default KtoConlab;

export { CommonContext };

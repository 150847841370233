import axios from 'axios';
import moment from 'moment';
import { changeKoglCodeToKoglType } from '../../shared/Function/util/KoglUtil';

enum ContentType {
    FORMAL = "FormalContents"
}

enum ImageContentType {
    GALLERY = "Gallery",
    RESOURCE = "Resource"
}

/* 필드명 다국어 설정을 위한 전역변수 선언 */
interface ConvertLangJson {
  [key: string]: {
    [key: string]: string;
  };
}

const convertLangJson: ConvertLangJson = {
  "카테고리": {
    "ko": "카테고리",
    "en": "Category",
    "ja": "カテゴリー",
    "zh_CN": "类别",
    "zh_TW": "類別",
    "de": "Kategorie",
    "fr": "Catégorie",
    "es": "Categoría",
    "ru": "Категория"
  },
  "개요": {
    "ko": "개요",
    "en": "Description",
    "ja": "概要",
    "zh_CN": "概要",
    "zh_TW": "簡介",
    "de": "Übersicht",
    "fr": "Aperçu",
    "es": "Resumen",
    "ru": "Описание"
  },
  "주소": {
    "ko": "주소",
    "en": "Address",
    "ja": "住所",
    "zh_CN": "地址",
    "zh_TW": "地址",
    "de": "Adresse",
    "fr": "Adresse",
    "es": "Dirección",
    "ru": "Адрес"
  },
  "전화번호": {
    "ko": "전화번호",
    "en": "Phone Number",
    "ja": "電話番号",
    "zh_CN": "电话",
    "zh_TW": "電話",
    "de": "Telefonnummer",
    "fr": "Numéro de téléphone",
    "es": "Número de teléfono",
    "ru": "Номер телефона"
  },
  "이용시간": {
    "ko": "이용시간",
    "en": "Hours of Operation",
    "ja": "利用時間",
    "zh_CN": "营业时间",
    "zh_TW": "營業時間",
    "de": "Öffnungszeiten",
    "fr": "Heures d'ouverture",
    "es": "Horario de apertura",
    "ru": "Время работы"
  },
  "휴무일": {
    "ko": "휴무일",
    "en": "Closing Days",
    "ja": "休業日",
    "zh_CN": "休息日",
    "zh_TW": "公休日",
    "de": "Ruhetage",
    "fr": "Jours de fermeture",
    "es": "Días de cierre",
    "ru": "Выходные дни"
  },
  "이용 요금": {
    "ko": "이용 요금",
    "en": "Fees & Rates",
    "ja": "利用料金",
    "zh_CN": "费用",
    "zh_TW": "費用",
    "de": "Kosten",
    "fr": "Frais d'utilisation",
    "es": "Tarifa de uso",
    "ru": "Стоимость "
  },
  "이용요금": {
    "ko": "이용요금",
    "en": "Fees & Rates",
    "ja": "利用料金",
    "zh_CN": "费用",
    "zh_TW": "費用",
    "de": "Kosten",
    "fr": "Frais d'utilisation",
    "es": "Tarifa de uso",
    "ru": "Стоимость "
  },
  "주차장": {
    "ko": "주차장",
    "en": "Parking",
    "ja": "駐車場",
    "zh_CN": "停车场",
    "zh_TW": "停車場",
    "de": "Parkplatz",
    "fr": "Parking",
    "es": "Estacionamiento",
    "ru": "Парковка"
  },
  "홈페이지": {
    "ko": "홈페이지",
    "en": "Website",
    "ja": "ホームページ",
    "zh_CN": "主页",
    "zh_TW": "網站",
    "de": "Homepage",
    "fr": "Page d'accueil",
    "es": "Página de inicio",
    "ru": "Главная страница сайта"
  },
  "정보제공": {
    "ko": "정보제공",
    "en": "Information Provider",
    "ja": "情報提供",
    "zh_CN": "信息提供",
    "zh_TW": "資訊出處",
    "de": "Informationsquelle",
    "fr": "Source des informations",
    "es": "Fuente de la información",
    "ru": "Источник информации"
  },
  "갱신일": {
    "ko": "갱신일",
    "en": "Last updated",
    "ja": "更新日",
    "zh_CN": "更新日期",
    "zh_TW": "更新日",
    "de": "Aktualisierungsdatum",
    "fr": "Date de mise à jour",
    "es": "Fecha de actualización",
    "ru": "Дата обновления"
  },
  "해시태그": {
    "ko": "해시태그",
    "en": "Hashtags",
    "ja": "ハッシュタグ",
    "zh_CN": "标签",
    "zh_TW": "標籤",
    "de": "Hashtags",
    "fr": "Hashtags",
    "es": "Etiquetas",
    "ru": "Хэштеги"
  },
  "기본정보": {
    "ko": "기본정보",
    "en": "General Information",
    "ja": "基本情報",
    "zh_CN": "基本信息",
    "zh_TW": "基本資訊",
    "de": "Allgemeine Informationen",
    "fr": "Informations de base",
    "es": "Información básica",
    "ru": "Основная информация"
  },
  "음식 : 취급메뉴": {
    "ko": "음식 : 취급메뉴",
    "en": "Menu ",
    "ja": "取扱いメニュー",
    "zh_CN": "菜单",
    "zh_TW": "菜單",
    "de": "Menü",
    "fr": "Menus traités",
    "es": "Menús ofrecidos",
    "ru": "Меню"
  },
  "체험가능연령": {
    "ko": "체험가능연령",
    "en": "Eligibility",
    "ja": "体験可能年齢",
    "zh_CN": "年龄限制",
    "zh_TW": "年齡限制",
    "de": "Altersbeschränkung",
    "fr": "Âge pour les activités",
    "es": "Edad permitida para las actividades",
    "ru": "Возраст участников"
  },
  "체험가능 프로그램": {
    "ko": "체험가능 프로그램",
    "en": "Experience Programs",
    "ja": "体験プログラム",
    "zh_CN": "体验项目",
    "zh_TW": "體驗活動",
    "de": "Erlebnisprogramm",
    "fr": "Programme d'activités",
    "es": "Programa de las actividades",
    "ru": "Программа мастер-класса"
  },
  "기간": {
    "ko": "기간",
    "en": "Duration",
    "ja": "期間",
    "zh_CN": "时间",
    "zh_TW": "活動日期",
    "de": "Dauer",
    "fr": "Durée",
    "es": "Duración",
    "ru": "Период"
  },
  "주관": {
    "ko": "주관",
    "en": "Organizer",
    "ja": "主催",
    "zh_CN": "主办",
    "zh_TW": "指導單位",
    "de": "Veranstalter",
    "fr": "Organisateur",
    "es": "Organizador",
    "ru": "Руководитель"
  },
  "주최": {
    "ko": "주최",
    "en": "Host",
    "ja": "開催",
    "zh_CN": "承办",
    "zh_TW": "主辦單位",
    "de": "Sponsor",
    "fr": "Hôte",
    "es": "Anfitrión",
    "ru": "Организатор"
  },
  "숙박요금": {
    "ko": "숙박요금",
    "en": "Room Rate",
    "ja": "宿泊料金",
    "zh_CN": "房费",
    "zh_TW": "房價",
    "de": "Kosten",
    "fr": "Frais d'hébergement",
    "es": "Tarifa de alojamiento",
    "ru": "Стоимость проживания"
  },
  "숙박시설등급": {
    "ko": "숙박시설등급",
    "en": "Accommodation Rating",
    "ja": "宿泊施設のランク",
    "zh_CN": "住宿设施等级",
    "zh_TW": "飯店等級",
    "de": "Unterkunftsklasse",
    "fr": "Classe d'hébergement",
    "es": "Clasificación del alojamiento",
    "ru": "Классификация объектов размещения"
  },
  "입실시간": {
    "ko": "입실시간",
    "en": "Check-in Time",
    "ja": "チェックイン",
    "zh_CN": "入住时间",
    "zh_TW": "進房時間",
    "de": "Check-in",
    "fr": "Heure d'arrivée",
    "es": "Hora de entrada",
    "ru": "Время заезда"
  },
  "퇴실시간": {
    "ko": "퇴실시간",
    "en": "Check-out Time",
    "ja": "チェックアウト",
    "zh_CN": "退房时间",
    "zh_TW": "退房時間",
    "de": "Check-out",
    "fr": "Heure check out",
    "es": "Hora de salida",
    "ru": "Время выезда"
  },
  "조식서비스": {
    "ko": "조식서비스",
    "en": "Breakfast",
    "ja": "朝食サービス",
    "zh_CN": "早餐服务",
    "zh_TW": "早餐服務",
    "de": "Frühstücksservice",
    "fr": "Service de petit-déjeuner",
    "es": "Servicio de desayuno",
    "ru": "Завтрак"
  },
  "객실명": {
    "ko": "객실명",
    "en": "Room Name",
    "ja": "お部屋の名前",
    "zh_CN": "客房名称",
    "zh_TW": "客房名稱",
    "de": "Zimmername",
    "fr": "Nom de la chambre",
    "es": "Nombre de la habitación",
    "ru": "Название номера"
  },
  "객실소개": {
    "ko": "객실소개",
    "en": "Room Description",
    "ja": "お部屋紹介",
    "zh_CN": "客房介绍",
    "zh_TW": "客房介紹",
    "de": "Zimmerbeschreibung",
    "fr": "Description de la chambre",
    "es": "Descripción de la habitación",
    "ru": "Описание номера"
  },
  "객실유형": {
    "ko": "객실유형",
    "en": "Room Type",
    "ja": "お部屋の種類",
    "zh_CN": "客房类型",
    "zh_TW": "客房類型",
    "de": "Zimmertyp",
    "fr": "Type de chambre",
    "es": "Tipo de habitación",
    "ru": "Тип номера"
  },
  "반려동물 동반정보": {
    "ko": "반려동물 동반정보",
    "en": "Pet policy",
    "ja": "ペット同伴情報",
    "zh_CN": "宠物同行信息",
    "zh_TW": "寵物友善資訊",
    "de": "Informationen zum Reisen mit Haustieren",
    "fr": "Informations accompagnement animaux de compagnie",
    "es": "Información sobre mascotas como acompañantes",
    "ru": "Информация о посещении с домашними животными"
  },
  "동반가능동물": {
    "ko": "동반가능동물",
    "en": "Pet Friendly",
    "ja": "同伴可能な動物",
    "zh_CN": "可携带宠物",
    "zh_TW": "允許寵物同行",
    "de": "Erlaubte Haustiere",
    "fr": "Animaux de compagnie autorisés",
    "es": "Mascotas permitidas",
    "ru": "Допускается посещение с домашними животными"
  },
  "동반시 필요사항": {
    "ko": "동반시 필요사항",
    "en": "Rules & Regulations",
    "ja": "同伴時の必要事項",
    "zh_CN": "同行要求事项",
    "zh_TW": "同行時必備物品",
    "de": "Vorschriften",
    "fr": "Règles pour l'accompagnement",
    "es": "Requisitos de los acompañantes",
    "ru": "Правила при сопровождении"
  },
  "동반관련 상세사항": {
    "ko": "동반관련 상세사항",
    "en": "Pet-friendly Guidelines",
    "ja": "ペット同伴情報",
    "zh_CN": "同行相关详情",
    "zh_TW": "同行時詳細資訊",
    "de": "Genauere Begleitinformationen",
    "fr": "Informations accompagnement (détails)",
    "es": "Información detallada sobre acompañamiento",
    "ru": "Детальная информация о сопровождении"
  },
  "구비시설": {
    "ko": "구비시설",
    "en": "Facilities & Amenities",
    "ja": "設備",
    "zh_CN": "设施",
    "zh_TW": "設施",
    "de": "Einrichtungen",
    "fr": "Installations",
    "es": "Instalaciones",
    "ru": "Удобства"
  },
  "사고 대비사항": {
    "ko": "사고 대비사항",
    "en": "Safety Items",
    "ja": "事故対策",
    "zh_CN": "事故防范措施",
    "zh_TW": "事故防範措施",
    "de": "Unfallverhütungssmaßnahmen",
    "fr": "Mesures de prévention des accidents",
    "es": "Medidas preventivas de accidentes",
    "ru": "Профилактические меры по предотвращению несчастных случаев"
  },
  "비치 품목": {
    "ko": "비치 품목",
    "en": "Provided Items",
    "ja": "提供品",
    "zh_CN": "提供物品",
    "zh_TW": "現場備品",
    "de": "Bereitgestellte Gegenstände",
    "fr": "Articles fournis",
    "es": "Artículos proporcionados",
    "ru": "Сопутствующие услуги и предметы"
  },
  "렌탈 품목": {
    "ko": "렌탈 품목",
    "en": "Rental Items",
    "ja": "レンタル品",
    "zh_CN": "租赁物品",
    "zh_TW": "租賃物品",
    "de": "Mietgegenstände",
    "fr": "Articles de location",
    "es": "Artículos de alquiler",
    "ru": "Вещи напрокат"
  },
  "구매 품목": {
    "ko": "구매 품목",
    "en": "Items for Sale",
    "ja": "購入品",
    "zh_CN": "自费物品",
    "zh_TW": "自費物品",
    "de": "Kaufartikel",
    "fr": "Articles à acheter",
    "es": "Artículos para comprar",
    "ru": "Товары"
  },
  "동반 구분": {
    "ko": "동반 구분",
    "en": "Pets Welcome",
    "ja": "同伴区分",
    "zh_CN": "宠物同行类型",
    "zh_TW": "寵物友善類別",
    "de": "Klassifizierung",
    "fr": "Type d'accompagnement",
    "es": "Tipo de acompañantes",
    "ru": "Разновидность сопровождения"
  },
  "등록정보": {
    "ko": "등록정보",
    "en": "Registration Information",
    "ja": "登録情報",
    "zh_CN": "注册信息",
    "zh_TW": "登錄資訊",
    "de": "Registrierungsinformationen",
    "fr": "Informations enregistrées ",
    "es": "Información del registro",
    "ru": "Информация о регистрации"
  }
};

class ContentBuilder {
  tid!: string; // 컨텐츠 tid
  cid: string; // 컨텐츠 cid
  contentType!: ContentType // 컨텐츠 타입
  contentDetail: any; // 컨텐츠 디테일 정보
  carouselImages!: {
    cid: string,
    imageDepotUrl: string
  }[]; // 캐로셀에 들어갈 컨텐츠 이미지 정보
  categoryId!: string; // 컨텐츠 카테고리 ID
  categoryPath!: string; // 컨텐츠의 3depth 카테고리 정보
  language!: string; // 컨텐츠 언어
  anotherLanguage: {
    cid: string,
    language: string
  }[] = []; // 번역된 다른 언어
  videoUrlAndThumbnail!: {
    title: string,
    value: {
      thumbnail: {
        title: string,
        value: string
      },
      youtubeUrl: {
        title: string,
        value: string
      }
    }
  }// 유튜브 영상 썸네일, URL
  coordinate: {
    language: string,
    country: string,
    zipCode: string,
    zipAddress: string,
    street: string,
    latitude: string,
    longitude: string,
    city: string,
    state: string,
    jibunAddress: string
  } = {
    language: '',
    country: '',
    zipCode: '',
    zipAddress: '',
    street: '',
    latitude: '',
    longitude: '',
    city: '',
    state: '',
    jibunAddress: ''
  }; //kakaomap을 위한 지도정보
  displayContentJson: any = {}; // 화면에서 렌더링할 json 구조의 정보
  formalContentBaseInfoKeys = [
    "title",
    "category",
    "introduction",
    "address",
    "map"
  ]; // 관광지 baseInfokey
  imageContentBaseInfoKeys = [
    "title",
    "category"
  ]; // 이미지 baseInfokey
  fileContentBaseInfoKeys = [
    "name",
    "fileName",
    "description"
  ]; // 파일 baseInfokey
  casualContentBaseInfoKeys = [
    "body"
  ]; // 기사 baseInfokey
  videoContentBaseInfoKeys = [
    "title",
    "categoryPath",
    "address",
    "videoDescription",
    "videoDuration",
    "ageLimit",
    "tags",
    "provider",
    "owner",
    "originRequestMethod"
  ]; // 동영상 baseInfokey
  courseContentBaseInfoKeys = [
    "courseIntro",
    "courseName",
    "courseTotalDist",
    "courseDuration",
    "tourismList"
  ]; // 추천코스 baseInfokey
  formalRegistrationInfoKeys = [
    "copyright",
    "registrationTime",
    "modificationTime"
  ]; // 관광지 등록정보 key
  resourseImageRegistrationInfoKeys = [
    "kogl",
    "owner",
    "provider"
  ]; // 리소스 타입 이미지 등록정보 key
  galleryImageRegistrationInfoKeys = [
    "kogl",
    "photoAuthor"
  ]; // 갤러리 타입 이미지 등록정보 key
  fileRegistrationInfoKeys = [
    "kogl",
    "copyright"
  ]; // 파일 등록정보 key
  videoRegistrationInfoKeys = [
    "kogl",
    "registrationTime",
    "modificationTime"
  ]; // 동영상 등록정보 key
  usageInfoKeys = [
    "telephone",
    "hoursofuse",
    "holiday",
    "usagefee",
    "parking",
    "homepage",
    "menus",
    "experienceage",
    "experienceprogram",
    "period",
    "sponsor",
    "host",
    "lodgingfare",
    "lodgegrade",
    "checkingtime",
    "checkouttime",
    "breakfast",
    "roomname",
    "roomcomment",
    "roomtype"
  ]; // 이용정보 key
  petCompanionkeys = [
    "RangePossibilityAccompaniedPet",
    "RequirementsForAccompany",
    "OtherInformation",
    "FacilitiesForPets",
    "AccidentPreparedness",
    "ItemsProvidedForPets",
    "RentalItems",
    "PurchasedItems",
    "CompanionSortation"
  ]; // 반려동물 동반정보 key
  lastStatusUpdaterCineroomName!: string;

  constructor(cid: string) {
    this.cid = cid;
  }

  /**
   * @description 기사 디테일 정보를 가져옵니다.
   */
  async findCasualContentByCid() {
    await axios.post("/api/naomi/flow/contents-flow/query/find-public-casual-contents-sdo-by-cid", {
      "responseName": "response",
      "cid": this.cid
    }).then((response) => {
      this.contentDetail = response.data.queryResult;
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 동영상 컨텐츠 디테일 정보를 가져옵니다.
   */
  async findVideoContentByCid() {
    await axios.post("/api/data/flow/7903dca4-dca2-4dca-b1e9-cc693b28999b", {
      "cid": this.cid
    }).then((response) => {
      this.contentDetail = response.data.queryResult;
      const videoUrlAndThumbnail = {
        title: "비디오 정보",
        value: {
          thumbnail: {
            title: "썸네일",
            value: this.contentDetail.youtubeThumbnailPath
          },
          youtubeUrl: {
            title: "유튜브 URL",
            value: `https://www.youtube.com/embed/${this.contentDetail.videoId}`
          }
        }
      }
      this.videoUrlAndThumbnail = videoUrlAndThumbnail
    }).catch((error) => {
      console.log(error);
    });

  }

  /**
   * @description 이미지 컨텐츠 디테일 정보를 가져옵니다.
   */
  async findImageContentByCid() {
    await axios.post('/api/data/flow/78d3ada9-e84c-4a3f-a863-16ce46969b0e', {
      "responseName": "response",
      "offset": {
        "limit": 10,
        "offset": 0,
        "sortDirection": "ASCENDING",
        "sortingField": "id",
        "totalCount": 0,
        "totalCountRequested": false
      },
      "queryParams": {
        "queryParams" : [
          {
            "connector": "End",
            "fieldName": "cid",
            "operation": "Equal",
            "value": this.cid
          }
        ]
      }
    }).then(async (response) => {
      await this.findImageContentElements(response.data.queryResult.id);
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 추천코스 컨텐츠 디테일 정보를 가져옵니다.
   */
  async findCourseContentByCid() {
    await axios.post("/api/naomi/flow/contents-flow/query/find-recommend-course-contents-by-cid", {
      "responseName": "response",
      "cid": this.cid
    }).then((response) => {
      this.contentDetail = response.data.queryResult;
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 이미지 컨텐츠의 id로 element속성을 구합니다.
   * @param imageId 이미지 컨텐츠 id
   */
  async findImageContentElements(imageId:string) {
    await axios.post('/api/data/flow/802898c6-2266-4201-bd33-840f171fd70d', {
      "responseName": "response",
      "offset": {
        "limit": 10,
        "offset": 0,
        "sortDirection": "ASCENDING",
        "sortingField": "id",
        "totalCount": 0,
        "totalCountRequested": false
      },
      "queryParams": {
        "queryParams" : [
          {
            "connector": "End",
            "fieldName": "id",
            "operation": "Equal",
            "value": imageId
          }
        ]
      }
    }).then((response) => {
      this.contentDetail = response.data.queryResult;
      this.categoryId = this.contentDetail.contents.categoryId;
      const depotUrl = `/api/depot/public/depot-flow/query/download-image/${this.contentDetail.contents.depotId}`
      const imageProp = {
        cid: this.cid,
        imageDepotUrl: depotUrl
      };
      this.carouselImages = [imageProp];
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 관광지 컨텐츠 디테일 정보를 가져옵니다.
   */
  async findFormalContentByCid() {
    await axios.post("/api/data/flow/481dd5e0-d89a-4055-a402-38f82fa5bb0c", {
      "responseName": "response",
      "cid": this.cid
    }).then((response) => {
      this.contentDetail = response.data.queryResult;
      this.tid = this.contentDetail.contents.tid;
      this.categoryId = this.contentDetail.contents.categoryId;
      this.language = this.contentDetail.contents.lang;
      this.contentType = this.contentDetail.contents.typeName.toUpperCase();
    }).catch((error) => {
      console.log(error);
    });
  }

  async findFileContentByCid() {
    await axios.post("/api/data/flow/9469bf57-9d11-46fe-9bee-b4d19da866c9", {
      "responseName": "response",
      "offset": {
        "offset": 0,
        "limit": 0,
        "totalCount": 0,
        "totalCountRequested": true,
        "sortDirection": "DESCENDING",
        "sortingField": ""
      },
      "cid": this.cid,
      "cineroomId": null,
      "contentsName": null,
      "categoryId": null,
      "addressKeyword": null,
      "fileType": null,
      "status": "Deployed",
      "publicType": true,
      "koglType": null,
      "aroundRequired": true
    }).then((response) => {
      this.contentDetail = response.data.queryResult;
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 컨텐츠의 이미지 cid를 얻기 위한 정보입니다.
   */
  async findContentImage() {
    await axios.post("/api/naomi/aggregate/contents/contents-link/query/dynamic-multi", {
        "responseName": "response",
        "offset": {
          "limit": 1000000,
          "offset": 0,
          "sortDirection": "DESCENDING",
          "sortingField": "registrationTime",
          "totalCount": 0,
          "totalCountRequested": true
        },
        "queryParams": {
          "queryParams": [
            {
              "connector": "End",
              "fieldName": "formalContentsCid",
              "operation": "Equal",
              "value": this.cid
            }
          ]
        }
      }).then(async (response) => {
        const originArray: any[] = response.data.queryResult;
        let cidArray: string[] = [];

        for (let i = 0; i < originArray.length; i++) {
            cidArray.push(originArray[i].imageContentsCid);
        }
        await this.findContentImageByCids(cidArray);

      }).catch((error) => {
        console.log(error);
      });
  }

  /**
   * @description cid로 이미지 URL을 가져옵니다.
   * @param cids cid 목록
   */
  async findContentImageByCids(cids: string[]) {
    await axios.post("/api/naomi/image-contents/cids", {
        "cids": cids
    }).then((response) => {
        this.carouselImages = response.data.queryResult;
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 컨텐츠 디테일의 categoryId로 3depth의 카테고리 정보를 얻어옵니다.
   */
  async findCategoryPathByCategoryId() {
    // category_lang_name에서 사용하는 중국어(간체) 및 중국어(번체) 코드값이 달라 _를 -로 바꿔서 조회해야함
    let language = this.language;
    if (language === 'zh_TW' || language === 'zh_CN') {
      language = language.replace("_", "-");
    }

    await axios.post("/api/naomi/flow/category-flow/query/find-category-lang-name-by-lang", {
        "responseName": "response",
        "withParents": true,
        "categoryIds": [
            this.categoryId
        ],
        "localeJson": language
    }).then((response) => {
        this.categoryPath = response.data.queryResult[0];
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 컨텐츠 디테일의 tid로 지도정보를 얻어옵니다.
   */
  async findLocationByTid() {
    await axios.post("/api/naomi/flow/tourism-flow/query/search-tourism-and-insert-loc", {
        "responseName": "response",
        "tid": this.tid
    }).then((response) => {
        this.coordinate = response.data.queryResult.address;
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 번역된 다른 언어정보를 가져옵니다.
   */
  async findAnotherLanguage() {
    await axios.post("/api/naomi/flow/square-flow/query/find-another-lang-public-contents-summaries", {
        "responseName": "response",
        "cid": this.cid,
        "tid": this.tid,
        "currentLang": this.language,
        "cineroomId": ""
    }).then((response) => {
        const queryResult = response.data.queryResult;

        let anotherLanguages: {
            cid: string,
            language: string
        }[] = [];

        for (let i = 0; i < queryResult.length; i++) {
            let anotherLanguage: {
                cid: string,
                language: string
            } = {
                cid: '',
                language: ''
            };

            anotherLanguage["cid"] = queryResult[i].cid;
            anotherLanguage["language"] = queryResult[i].lang;

            anotherLanguages.push(anotherLanguage);
        }

        this.anotherLanguage = anotherLanguages;
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 컨텐츠의 last_status_updater_cineroom_name를 가져옵니다.
   */
  async findLastStatusCineroomName() {
    await axios.post('/api/naomi/flow/square-flow/query/find-element-cineroom-name-by-cid', {
      "responseName": "response",
      "cid": this.cid
    }).then((response) => {
      this.lastStatusUpdaterCineroomName = response.data.queryResult;
    }).catch((error) => {
      console.log(error);
    });
  }

  /**
   * @description 관광지 컨텐츠를 빌드합니다.
   */
  async buildFormalContent() {
    await this.requestFormalContentData();
    this.addLanguages();
    this.addImages();
    this.addFormalTemplate();
  }

  /**
   * @description 기사 컨텐츠를 빌드합니다.
   */
  async buildCasualContent() {
    await this.findCasualContentByCid();
    this.addCasualTemplate();
  }

  /**
   * @description 이미지 컨텐츠를 빌드합니다.
   */
  async buildImageContent() {
    await this.requestImageContentData();
    this.addImages();
    this.addImageTemplate();
  }

  /**
   * @description 동영상 컨텐츠를 빌드합니다.
   */
  async buildVideoContent() {
    await this.findVideoContentByCid();
    this.addVideoTempate();
  }

  /**
   * @description 추천코스 컨텐츠를 빌드합니다.
   */
  async buildCourseContent() {
    await this.findCourseContentByCid();
    this.addCourseTempate();
  }

  /**
   * @description 파일 컨텐츠를 빌드합니다.
   */
  async buildFileContent() {
    await this.findFileContentByCid();
    this.addFileTempate();
  }

  /**
   * @description 관광지 컨텐츠 정보를 빌드하기 위해 필요한 정보들을 요청합니다.
   */
  async requestFormalContentData() {
    await this.findFormalContentByCid();
    await this.findContentImage();
    await this.findCategoryPathByCategoryId();
    await this.findLocationByTid();
    await this.findAnotherLanguage();
  }

  /**
   * @description 이미지 컨텐츠 정보를 빌드하기 위해 필요한 정보들을 요청합니다.
   */
  async requestImageContentData() {
    await this.findImageContentByCid();
    await this.findCategoryPathByCategoryId();
  }

  /**
   * @description 번역된 다른 언어를 세팅합니다.
   */
  addLanguages() {
    this.displayContentJson["lang"] = this.anotherLanguage;
  }

  /**
   * @description 캐로셀에 들어갈 이미지들을 세팅합니다.
   */
  addImages() {
    this.displayContentJson["image"] = this.carouselImages;
  }

  /**
   * @description formal 컨텐츠의 Template을 세팅합니다.
   */
  addFormalTemplate() {
    let baseInfo = {
        title: "기본정보",
        value: {}
    };
    let registrationInfo = {
        title: convertLangJson["등록정보"][this.language],
        value: {}
    };
    let usageInfo = {
        title: "이용정보",
        value: {}
    };
    let petCompanion = {
        title: "반려동물 동반정보",
        value: {}
    };

    this.displayContentJson["baseInfo"] = baseInfo;
    this.displayContentJson["registrationInfo"] = registrationInfo;
    this.displayContentJson["usageInfo"] = usageInfo;
    this.displayContentJson["petCompanion"] = petCompanion;

    // baseInfo 세팅
    for (let i = 0; i < this.formalContentBaseInfoKeys.length; i++) {
      const key = this.formalContentBaseInfoKeys[i];
      const infoValue = this.searchValueByKey(key);
      // if (infoValue.value) {
      //   this.displayContentJson.baseInfo.value[key] = infoValue;
      // }
      this.displayContentJson.baseInfo.value[key] = infoValue;
    }

    // registrationInfo 세팅
    for (let i = 0; i < this.formalRegistrationInfoKeys.length; i++) {
      const key = this.formalRegistrationInfoKeys[i];
      const infoValue = this.searchValueByKey(key);
      // if (infoValue.value) {
      //   this.displayContentJson.registrationInfo.value[key] = infoValue;
      // }
      this.displayContentJson.registrationInfo.value[key] = infoValue;
    }

    // usageInfo 세팅
    for (let i = 0; i < this.usageInfoKeys.length; i++) {
      const key = this.usageInfoKeys[i];
      const infoValue = this.searchValueByKey(key);
      // if (infoValue.value) {
      //   this.displayContentJson.usageInfo.value[key] = infoValue;
      // }
      this.displayContentJson.usageInfo.value[key] = infoValue;
    }

    // petCompanion 세팅
    for (let i = 0; i < this.petCompanionkeys.length; i++) {
      const key = this.petCompanionkeys[i];
      const infoValue = this.searchValueByKey(key);
      // if (infoValue.value) {
      //   this.displayContentJson.petCompanion.value[key] = infoValue;
      // }
      this.displayContentJson.petCompanion.value[key] = infoValue;
    }
  }

  /**
   * @description Casual 컨텐츠의 Template을 세팅합니다.
   */
  addCasualTemplate() {
    let baseInfo = {
      title: "기본정보",
      value: {}
    };
    this.displayContentJson["baseInfo"] = baseInfo;

    // baseInfo 세팅
    for (let i = 0; i < this.casualContentBaseInfoKeys.length; i++) {
      const key = this.casualContentBaseInfoKeys[i];
      const infoValue = this.searchValueByKey(key);
      this.displayContentJson.baseInfo.value[key] = infoValue;
    }

  }

  /**
   * @description Image 컨텐츠의 Template을 세팅합니다.
   */
  addImageTemplate() {
    let baseInfo = {
      title: "기본정보",
      value: {}
    };

    let registrationInfo = {
        title: "등록정보",
        value: {}
    };

    this.displayContentJson["baseInfo"] = baseInfo;
    this.displayContentJson["registrationInfo"] = registrationInfo;


    // baseInfo 세팅
    for (let i = 0; i < this.imageContentBaseInfoKeys.length; i++) {
      const key = this.imageContentBaseInfoKeys[i];
      const infoValue = this.searchValueByKey(key);
      this.displayContentJson.baseInfo.value[key] = infoValue;
    }

    // registrationInfo 세팅
    switch(this.contentDetail.contents.type) {
      case ImageContentType.GALLERY:
        for (let i = 0; i < this.galleryImageRegistrationInfoKeys.length; i++) {
          const key = this.galleryImageRegistrationInfoKeys[i];
          const infoValue = this.searchValueByKey(key);
          this.displayContentJson.registrationInfo.value[key] = infoValue;
        }
        break;
      case ImageContentType.RESOURCE:
        for (let i = 0; i < this.resourseImageRegistrationInfoKeys.length; i++) {
          const key = this.resourseImageRegistrationInfoKeys[i];
          const infoValue = this.searchValueByKey(key);
          this.displayContentJson.registrationInfo.value[key] = infoValue;
        }
        break;
    }
  }

  /**
   * @description File 컨텐츠의 Template을 세팅합니다.
   */
  addFileTempate() {
    let baseInfo = {
      title: "기본정보",
      value: {}
    };

    let registrationInfo = {
        title: "등록정보",
        value: {}
    };

    this.displayContentJson["baseInfo"] = baseInfo;
    this.displayContentJson["registrationInfo"] = registrationInfo;
    const fileContentDetail = this.contentDetail;

    // baseInfo 세팅
    this.displayContentJson.baseInfo.value["name"] = fileContentDetail.element.name;
    this.displayContentJson.baseInfo.value["fileName"] = fileContentDetail.fileName;
    this.displayContentJson.baseInfo.value["description"] = fileContentDetail.element.description;

    // registrationInfo 세팅
    this.displayContentJson.registrationInfo.value["kogl"] = fileContentDetail.copyright.kogl.type;
    this.displayContentJson.registrationInfo.value["copyright"] = fileContentDetail.copyright.owner;
  }

  /**
   * @description video 컨텐츠의 Template을 세팅합니다.
   */
  addVideoTempate() {
    let baseInfo = {
      title: "기본정보",
      value: {}
    };

    let registrationInfo = {
        title: "등록정보",
        value: {}
    };

    this.displayContentJson["video"] = this.videoUrlAndThumbnail;
    this.displayContentJson["baseInfo"] = baseInfo;
    this.displayContentJson["registrationInfo"] = registrationInfo;

    // baseInfo 세팅
    for (let i = 0; i < this.videoContentBaseInfoKeys.length; i++) {
      const key = this.videoContentBaseInfoKeys[i];
      const infoValue = this.searchValueByKeyForSingleModel(key);
      this.displayContentJson.baseInfo.value[key] = infoValue;
    }

    // registrationInfo 세팅
    for (let i = 0; i < this.videoRegistrationInfoKeys.length; i++) {
      const key = this.videoRegistrationInfoKeys[i];
      const infoValue = this.searchValueByKeyForSingleModel(key);
      this.displayContentJson.registrationInfo.value[key] = infoValue;
    }
  }

  /**
   * @description course 컨텐츠의 Template을 세팅합니다.
   */
  addCourseTempate() {
    let baseInfo = {
      title: "기본정보",
      value: {}
    };

    this.displayContentJson["baseInfo"] = baseInfo;

    // baseInfo 세팅
    for (let i = 0; i < this.courseContentBaseInfoKeys.length; i++) {
      const key = this.courseContentBaseInfoKeys[i];
      const infoValue = this.searchValueByKeyForSingleModel(key);
      this.displayContentJson.baseInfo.value[key] = infoValue;
    }
  }

  searchValueByKeyForSingleModel(key: string) {
    let retValue = {
      title: '',
      value: this.contentDetail[key]
    };

    if (key === "title") {
      retValue.title = "제목";
    } else if (key === "categoryPath") {
      retValue.title = "카테고리";
    } else if (key === "address") {
      retValue.title = "주소";
    } else if (key === "videoDescription") {
      retValue.title = "동영상 설명";
    } else if (key === "videoDuration") {
      retValue.title = "영상 재생 시간";
    } else if (key === "ageLimit") {
      retValue.title = "연령제한";
    } else if (key === "tags") {
      retValue.title = "태그";
    } else if (key === "provider") {
      retValue.title = "출처(소유자)";
    } else if (key === "owner") {
      retValue.title = "저작권자명";
    } else if (key === "originRequestMethod") {
      retValue.title = "원본 요청 방법";
    } else if (key === "kogl") {
      retValue.title = "저작권";
    } else if (key === "registrationTime") {
      retValue.title = "등록일";
    } else if (key === "modificationTime") {
      retValue.title = "갱신일";
    } else if (key === "courseDuration") {
      retValue.title = "소요시간";
    } else if (key === "courseIntro") {
      retValue.title = "개요";
    } else if (key === "courseName") {
      retValue.title = "제목";
    } else if (key === "courseTotalDist") {
      retValue.title = "총 거리";
    } else if (key === "tourismList") {
      retValue.title = "추천코스";
    }

    return retValue;
  }

  /**
   * @description 주어진 key로 contentAttr에서 value를 찾습니다.
   * @param key 찾을 key
   */
  searchValueByKey(key: string) {
    const contentAttrs = this.contentDetail?.contentsAttrs;
    let retValue = {
        title: '',
        value: ''
    };

    // 컨텐츠 제목,등록시간, 수정시간은 element에 있음.
    if (key === "title") {
      // 컨텐츠 제목 가져오기
      retValue.title = "제목";
      retValue.value = this.contentDetail?.element.name;
      return retValue
    } else if (key === "registrationTime") {
      retValue.title = "등록일";
      retValue.value = moment(this.contentDetail?.element.registrationTime).format('YYYY-MM-DD');
      return retValue;
    } else if (key === "modificationTime") {
      retValue.title = convertLangJson["갱신일"][this.language];
      retValue.value = moment(this.contentDetail?.element.modificationTime).format('YYYY-MM-DD');
      return retValue;
    } else if (key === "category") {
      retValue.title = convertLangJson["카테고리"][this.language];
      retValue.value = this.categoryPath;
    } else if (key === "map") {
      retValue.title = "위치";
      // @ts-ignore
      retValue.value = {
        latitude: this.coordinate.latitude,
        longitude: this.coordinate.longitude
      };
    } else if (key === "kogl") {
      retValue.title = "저작권";
      const imagePropertyKoglKey = Object.keys(this.contentDetail?.contents.imageProperty);
      // if (this.contentDetail?.contents.copyright?.kogl) {
      //   retValue.value = this.contentDetail?.contents.copyright?.kogl.type;
      // } else if (imagePropertyKoglKey.includes("kogl")) {
      //   const koglTypeValue = this.contentDetail?.contents.imageProperty?.kogl.code;
      //   const convertToKoglType = changeKoglCodeToKoglType(koglTypeValue);
      //   retValue.value = convertToKoglType;
      // }

      switch(this.contentDetail.contents.type) {
        case ImageContentType.GALLERY:
          if (imagePropertyKoglKey.includes("kogl")) {
            const koglTypeValue = this.contentDetail?.contents.imageProperty?.kogl.code;
            const convertToKoglType = changeKoglCodeToKoglType(koglTypeValue);
            retValue.value = convertToKoglType;
          }
          break;
        case ImageContentType.RESOURCE:
          if (this.contentDetail?.contents.copyright?.kogl) {
            retValue.value = this.contentDetail?.contents.copyright?.kogl.type;
          }
          break;
      }
    } else if (key === "owner") {
      let displayOwner = '';
      if (this.contentDetail?.contents.copyright?.owner && this.contentDetail?.contents.copyright?.owner !== "none" && this.contentDetail?.contents.copyright?.owner !== "" && this.contentDetail?.contents.copyright?.owner !== undefined &&  this.contentDetail.contents.copyright?.owner !== '.' && this.contentDetail.contents.copyright?.owner !== '-') {
        displayOwner = this.contentDetail.contents.copyright.owner;
      }
      retValue.title = "사진출처";
      retValue.value = displayOwner;
    } else if (key === "provider") {
      let displayCopyright = '';

      if (this.contentDetail?.contents.copyright?.title && this.contentDetail?.contents.copyright?.title !== "none" && this.contentDetail?.contents.copyright?.title !== null && this.contentDetail?.contents.copyright?.title.trim() !== '' && this.contentDetail?.contents.copyright?.title !== '.' && this.contentDetail?.contents.copyright?.title !== '-') {
        displayCopyright = this.contentDetail?.contents.copyright?.title;
      } else if (this.contentDetail?.contents.copyright?.title && this.contentDetail?.contents.copyright?.title !== undefined) {
        displayCopyright = this.contentDetail?.contents.copyright.owner;
      }
      retValue.title = "저작권자명";
      retValue.value = displayCopyright;
    } else if (key === "photoAuthor") {
      retValue.title = "사진작가";
      retValue.value = this.contentDetail.contents.imageProperty.photoAuthor || "";
    }

    // 컨텐츠 value를 찾는 Iteration
    for (let i = 0; i < contentAttrs?.length; i++) {
      // key에 맞는 attrType.code를 찾는다.
      if (contentAttrs[i].typeName === "SingleContentsAttr") {
        if (key === contentAttrs[i].attrType.code) {
          // SingleContentsAttr일 경우 0번 인덱스 value에 값이 있음.
          retValue.title = convertLangJson[contentAttrs[i].attrType.name]?.[this.language] || contentAttrs[i].attrType.name;
          retValue.value = contentAttrs[i].attrs[0].value;
          if (key === "parking" && contentAttrs[i].attrs[0].value === "1") {
            retValue.value = '주차 가능';
          } else if (key === "parking" && contentAttrs[i].attrs[0].value === "0") {
            retValue.value = '주차 불가';
          }
          return retValue;
        }
      } else if (contentAttrs[i].typeName === "GroupContentsAttr") {
        // GroupContentsAttr일 경우 subContentsAttrs을 Iteration 해야함
        for (let groupIdx = 0; groupIdx < contentAttrs[i].groupValues[0].subContentsAttrs.length; groupIdx++) {
          if (key === contentAttrs[i].groupValues[0].subContentsAttrs[groupIdx].attrType.code) {
            retValue.title = convertLangJson[contentAttrs[i].groupValues[0].subContentsAttrs[groupIdx].attrType.name]?.[this.language]
                || contentAttrs[i].groupValues[0].subContentsAttrs[groupIdx].attrType.name;
            retValue.value = contentAttrs[i].groupValues[0].subContentsAttrs[groupIdx].attrs[0].value;
            if ((key === 'lodgegrade' && contentAttrs[i].groupValues[0].subContentsAttrs[groupIdx].attrs[0].value)) {
              let checkKeyword = JSON.parse(contentAttrs[i].groupValues[0].subContentsAttrs[groupIdx].attrs[0].value);
              // @ts-ignore
              retValue.value = this.filterLodgeGradeValue(checkKeyword.name);
            }
            if (key === "parking" && contentAttrs[i].groupValues[0].subContentsAttrs[groupIdx].attrs[0].value === "1") {
              retValue.value = '주차 가능';
            } else if (key === "parking" && contentAttrs[i].groupValues[0].subContentsAttrs[groupIdx].attrs[0].value === "0") {
              retValue.value = '주차 불가';
            }
            return retValue;
          }
        }
      }
    }
    return retValue;
  }

  /**
   * @description 숙박등급 키워드에 값을 체크
   * @param keyword
   * @returns
   */
  filterLodgeGradeValue(keyword: string) {
    const forbiddenKewords: string[] = ['==========', '===========', '=========', '선택', '선택안함', ''];
    let retValue = '';
    const isForbidden = forbiddenKewords.includes(keyword);

    if (isForbidden) {
      retValue = '';
    } else {
      retValue = keyword;
    }

    return retValue;
  }

  getContentDetailInfo() {
    return this.displayContentJson;
  }

  getContentDetail() {
    return this.contentDetail;
  }
}

export default ContentBuilder;

import React, { ContextType } from "react";
import CommonContext from "../context/CommonContext";
import { currentUrlCheck, topSessionclear } from "../conlab";
import { findStageChartById } from "../shared/Function/stage/Function";
import CitizenUser from "../model/User/CitizenUser";
import Alert from "./Alert";
import { Chip } from "@mui/material";
import { checkProxy } from "../shared/Function/checkpoint/Function";

interface Props {
  isModal: boolean;
}

interface State {
  isAlert: boolean;
}

class LoginPannel extends React.Component<Props, State> {
  static defaultProps: any = {};
  static contextType = CommonContext;
  private navyRMSKollectionName = 'orms-app';
  private orangeRMSKollectionName = 'rms-app';

  domain = window.location.host;
  context!: ContextType<typeof CommonContext>;

  constructor(props: any) {
    super(props);
    this.openAlert = this.openAlert.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  //@ts-ignore
  state: State = { 
    isAlert: false
  };

  componentDidMount() {
    
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {

  }

  async topLogin(event: any) {
    currentUrlCheck();
    // await axios.post("https://conlab.visitkorea.or.kr/api/checkpoint/sso/top/site-id")
    // .then((response) => {
    //   console.log(response.data.queryResult);
    //   console.log('linkSiteApiId : ', response.data.queryResult);

    //   const reqUrl = 'https://pass.knto.or.kr/front/login/login?linkSiteApiId=';
    //   const linkSiteApiId = response.data.queryResult;

    //   //투어원패스 팝업 셋팅
    //   const uri = reqUrl + linkSiteApiId;
    //   const popupName = '_blank';
    //   const options = 'width=560, height=600'; //팝업사이즈

    //   //투어원패스 팝업 호출
    //   const win = window.open(uri, popupName, options);
    //   return win;
    // });

    event.preventDefault();

    const data = await checkProxy();
    if (data) {
      location.href = 'http://eportal.knto.or.kr:8080/public/script/redirectGateway.jsp?gubun=conlab&param=login';
      return;
    }

    const reqUrl = "https://pass.knto.or.kr/front/login/login?linkSiteApiId=";

    //투어원패스 팝업 셋팅
    const uri = reqUrl + "NTVsTUyyO8O8WueBigtM5A";
    const popupName = "_blank";
    const options = "width=560, height=600"; //팝업사이즈

    //투어원패스 팝업 호출
    const win = window.open(uri, popupName, options);
    return win;
  }

  routeWorkspace() {
    window.open(`https://${this.domain}/rms`);
  }

  routeKtoRms() {
    window.open(`https://${this.domain}/kto-rms`);
  }

  routeDev(){
    window.open(`https://${this.domain}/developers`);
  }

  logOut() {
    topSessionclear();
    window.location.reload();
  }

  checkIndividualMember() {
    const { citizenUser } = this.context;
    let isIndividual = true;
    if (citizenUser) {
      isIndividual = citizenUser.cineroomIds.includes("1:1:a");
    }
    return isIndividual;
  }

  openAlert() {
    this.setState({isAlert: true});
  }

  closeAlert() {
    this.setState({isAlert: false});
  }

  routeJoinPage(event: any) {
    event.preventDefault();
    window.location.href = "/rms-conlab/join";
  }

  render() {
    const { isModal = false } = this.props;
    const { isLogin, citizenUser, accessibleCasts, closeLoginModal } = this.context;
    const { isAlert } = this.state;
    let isIndividual = true;
    let isOrangeRMSAccessible = false;
    // @ts-ignore
    const isCheckedProfile = JSON.parse(window.localStorage.getItem("nara.isCheckedProfile")) || this.context.isCheckedProfile;

    if (isLogin) {
      isIndividual = this.checkIndividualMember();
      isOrangeRMSAccessible = accessibleCasts.some((node) => node.kollectionName === this.orangeRMSKollectionName);
    }

    return (
      <>
        <div className="login_box">
        {isModal && (
        <button className="btn_close" style={{position: 'absolute', top: '3px', right: '8px', padding: '9px 3px'}} onClick={() => closeLoginModal()}>
          <img src="/rms-conlab/images/btn_close.png" alt="btn_close" />
        </button>)}
          {isLogin && isCheckedProfile ? (
            <>
              <h2>
                안녕하세요
                <br />
                <span>{citizenUser.displayName} 님</span>
              </h2>
              <ul className="my_links">
                <li className="work" onClick={() => this.routeWorkspace()}>
                  <a>워크스페이스<Chip label="PC전용" style={{fontWeight:'bold', marginLeft: '10px'}}/></a>
                </li>
                {isIndividual === false && (
                  <>
                    <li className="dev" onClick={() => this.routeDev()}>
                      <a>개발자센터</a>
                    </li>
                    {isOrangeRMSAccessible && (
                    <li className="rms" onClick={() => this.routeKtoRms()}>
                      <a>KTO-RMS</a>
                    </li>)}
                  </>
                )}
              </ul>
              <div className="login_bts">
                <button 
                  className="login_bt st_line ok"
                  onClick={this.logOut}
                  style={{display: 'block', margin: '0 auto'}}
                >로그아웃
                </button>
              </div>
            </>
          ) : (
            <>
              <form action="" className="pannel_from">
                <h2 style={{width: '100%'}}>
                  로그인
                  <br />
                  해주세요
                </h2>
                <section className="top_desc">
                  <ul>
                    <li>
                    한국관광공사에서 운영하는 간편 로그인을 통해 풍성한 관광콘텐츠를 즐겨보세요.
                    </li>
                  </ul>
                </section>
                <div className="login_bts">
                  <button
                    className="combined_login_bt"
                    onClick={(e) => this.topLogin(e)}
                  >
                    간편로그인
                    <img
                      src="/rms-conlab/images/Tour_Onepass.png"
                      alt="Tour_Onepass"
                    />
                  </button>
                  <button
                    onClick={this.routeJoinPage}
                    className="join_bt"
                    style={{display: 'block', margin: '0 auto' , background : "rgb(255 255 255 / 50%)"}}
                  >
                    <p style={{color: '#2658C6' }}>회원가입</p>
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
        {/* {isAlert && <Alert message="개발자 센터는 추후 오픈 예정입니다." eventCloseModal={this.closeAlert}/>} */}
      </>

    );
  }
}

export default LoginPannel;

import React from "react";
import "../../css/style.min.css";
import "../../css/member.css";
import Accordion from "@mui/material/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import KntoPolicyText from "../../PolicyViewText/KntoPolicyText";
import UsingRoleText from "../../PolicyViewText/UsingRoleText";
import CopyRightText from "../../PolicyViewText/CopyRightText";
import CommonModal from "../../conlab/CommonModal";
import autobind from "../../lib/decorator/autobind/autobindDecorator";
import { getOrgType, removeOrgType, removeSessionSsoId } from "../../conlab";
import OrgProposal from "../api-model/OrgProposal";

interface Props {
  onNextStep: (event: React.MouseEvent) => void;
  renderPolicy?: {
    kntoPolicy: JSX.Element;
    copyright: JSX.Element;
    usingRole: JSX.Element;
  };
}

interface State {
  expanded: string | false;
  agree: boolean[];
  isAllChecked: boolean;
  openModal: boolean;
  modalText: string;
  open: boolean;
  explainTextArea: JSX.Element;
}

@autobind
class PolicyView extends React.Component<Props, State> {
  static defaultProps: any = {};

  state = {
    expanded: "panel1",
    agree: [false, false, false, false],
    isAllChecked: false,
    openModal: false,
    modalText: "",
    open: false,
    explainTextArea: <></>,
  };

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      this.setState({ expanded: newExpanded ? panel : false });
    };

  show(view: string) {
    switch (view) {
      case "KntoPolicyText":
        this.setState({ open: true, explainTextArea: <KntoPolicyText /> });
        break;
      case "UsingRoleText":
        this.setState({ open: true, explainTextArea: <UsingRoleText /> });
        break;
      case "CopyRightText":
        this.setState({ open: true, explainTextArea: <CopyRightText /> });
        break;
      default:
        break;
    }
  }

  showAlert() {
    const { isAllChecked } = this.state;
    if (!isAllChecked) {
      this.setState({ openModal: true, modalText: '모든 약관에 동의하여 주십시오.' });
    }
  }

  hide() {
    this.setState({ open: false });
  }

  handleCheck(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const agree = [...this.state.agree];
    
    agree[index] = event.target.checked;

    this.setState({ agree });
    this.setState({ isAllChecked: agree.every(e => e === true) });
  }

  onClickAllCheck(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    const target = event.target.checked;

    const agree = this.state.agree.map(() => target);
      this.setState({ agree });

    this.setState({ isAllChecked: target });
  }

  modalCon() {
    this.setState({ openModal: false, modalText: '' });
  }

  routeMain() {
    removeSessionSsoId();
    removeOrgType();
    window.location.href='/rms-conlab/join';
  }

  render() {
    const { onNextStep } = this.props;
    const { expanded, agree, openModal, modalText, isAllChecked} = this.state;
    const isNext = isAllChecked;

    return (
      <>
        <section className="step-wrap">
          {
            openModal ? <CommonModal message={modalText} close={this.modalCon} /> : null
          }
          <div className="step step-1">
            <div className="step-title">
                <h2>약관동의</h2>
                <span className="point-comment">표는 필수 동의 항목입니다.</span>
            </div>
            <div className="step-content">
              <div className="agree-list">
                  <input type="checkbox" checked={isAllChecked}
                    onChange={e => this.onClickAllCheck(e)}
                    name="checkbox-1" id="checkbox-1" className="checkbox checkbox-all" />
                  <label htmlFor="checkbox-1">전체동의 <span className="required"></span></label>
              </div>

              <div className="agree-list">
                <input type="checkbox" name="agree" id="checkbox-2"
                  checked={agree[0]}
                  onChange={e => this.handleCheck(e, 0)}/>
                <label htmlFor="checkbox-2">개인정보 수집 및 이용문의 <span className="required"></span></label>
                <Accordion
                  variant="outlined"
                  className="accoredion-simple"
                  expanded={expanded === "panel1"}
                  onChange={this.handleChange("panel1")}
                >
                  <AccordionSummary expandIcon={<div className="expand-icon" />}>
                    <Button
                      className="btn-reset copy"
                      onClick={() => this.show("KntoPolicyText")}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <KntoPolicyText />
                  </AccordionDetails>
                </Accordion>
              </div>
              

              <div className="accoredion-split-line" />

              <div className="agree-list">
                <input type="checkbox" name="agree" id="checkbox-3"
                  checked={agree[1]}
                  onChange={e => this.handleCheck(e, 1)}/>
                <label htmlFor="checkbox-3">이용약관 동의 <span className="required"></span></label>
                <Accordion
                  variant="outlined"
                  className="accoredion-simple"
                  expanded={expanded === "panel2"}
                  onChange={this.handleChange("panel2")}
                >
                  <AccordionSummary expandIcon={<div className="expand-icon" />}>
                    <Button
                        className="btn-reset copy"
                        onClick={() => this.show("UsingRoleText")}
                      />
                  </AccordionSummary>
                  <AccordionDetails>
                    <UsingRoleText />
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="accoredion-split-line" />


              <div className="agree-list">
                <input type="checkbox" name="agree" id="checkbox-4"
                  checked={agree[2]}
                  onChange={e => this.handleCheck(e, 2)}/>
                <label htmlFor="checkbox-4">저작권 정책 동의 <span className="required"></span></label>
                <Accordion
                  variant="outlined"
                  className="accoredion-simple"
                  expanded={expanded === "panel3"}
                  onChange={this.handleChange("panel3")}
                >
                  <AccordionSummary expandIcon={<div className="expand-icon" />}>
                    <Button
                        className="btn-reset copy"
                        onClick={() => this.show("CopyRightText")}
                      />
                  </AccordionSummary>
                  <AccordionDetails>
                    <CopyRightText />
                  </AccordionDetails>
                </Accordion>
              </div>

              {/* <div className="accoredion-split-line" /> */}

              <div className="accoredion-split-line" />

              <div className="agree-list">
                <input type="checkbox" name="checkbox-5"
                  checked={agree[3]}
                  onChange={e => this.handleCheck(e, 3)}
                  id="checkbox-5" className="checkbox checkbox-5" />
                <label htmlFor="checkbox-5">만 14세 이상입니다. <span className="required"></span></label>
              </div>
              <div className="step-btns">
                  <button className="btn-outline" onClick={this.routeMain}>취소</button>
                  <button className="btn-default" onClick={isNext ? onNextStep : this.showAlert}>다음</button>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PolicyView;

import axios from 'axios';
import { apiURL } from '..';
import { findProfileByCitizenId } from '../profile/Function';
import { findCineroomById } from '../metro/Function';
import { idDuplicationCheckFuc } from './SecureCommonFlow';

const checkpointURL = apiURL+'/api/checkpoint';
export const secureFlowURL = checkpointURL + '/secure/common-flow';

export enum UserType {
  Servant = 'SERVANT',
  Citizen = 'CITIZEN',
}

export const checkpointSecureCommonFlowFunction = async (requestUrl: string, data: any) => {
  const result = await axios.post(`${secureFlowURL+requestUrl}`, data);
  return result.data;
}

export const idDuplicationCheck = async (
  loginId: string
) => {
  
  const data = {
    loginId,
    userType: UserType.Servant,
    domainUrl: ''
  }
  const servant = await idDuplicationCheckCitizenType(data);
  data.userType = UserType.Citizen
  const citizen = await idDuplicationCheckCitizenType(data);
  return servant && citizen;
}

const idDuplicationCheckCitizenType = async (data: any) => {
  let valid: any = false;
  switch (data.userType) {
    case UserType.Servant:
      valid = (await idDuplicationCheckFuc(data)).valueOf();
      break;
    case UserType.Citizen:
      data.domainUrl = window.location.hostname;
      valid = (await idDuplicationCheckFuc(data)).valueOf();
      break;
    default:
      throw Error(`usertype must be one of SERVANT, CITIZEN but ${data.userType}`);
  }
  return valid;
}

export const createTopCitizen = async (
  citizenId: string,
  ssoId: string
) => {
  const data = {
    citizenId,
    ssoId,
  }

  await axios.post(checkpointURL+`/sso/top/create-top-citizen`, data);
}

export const findCitizenByCitizenId = async (citizenId: string) => {
  const data = {
    pniToken: '',
    email: '',
    citizenId,
    challenge: ''
  }
  const res = await axios.post(checkpointURL+'/sso/top-exist-citizen', data);
  return res.data;
}

export const onClickTopLogin = async (id: string, pw: string, ssoId: string) => {
  const postData = new FormData();
  // 계정 연동
  postData.append('grant_type', 'password');
  postData.append('username', id);
  postData.append('password', pw);
  postData.append('scope', 'citizen');
  postData.append('pavilion-domain-url', 'conlab.visitkorea.or.kr');

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + btoa('nara:narasecret'),
    },
    noAuth: true,
    noCatch: true,
    timeout: 10000
  };

  const res = await axios.post(checkpointURL+'/oauth/token', postData, config);
  return res;
}

export const checkCitizen =async (loginId: string, password: string) => {
  const data = {
    loginId,
    password
  }
  const res = await axios.post(checkpointURL+'/secure/citizenuser-flow'+'/check-citizen', data);
  return res.data;
}

const toCitizenUser = (loginId: string, displayName: string, citizenId: string, email: string, cineroomIds: string) => {
  return {
    id:"",
    entityVersion:0,
    registrationTime:0,
    modificationTime:0,
    loginId: loginId,
    displayName: displayName,
    encryptedPassword:"",
    email: email,
    citizenId: citizenId,
    domainUrl: "",
    idType: null,
    cineroomIds: cineroomIds,
    loggedin: true,
    additionalInformation: null,
    passwordResetInfo: null,
    accessToken: ""
  };
}

export const setLoginSession = (
  access_token: any, refresh_token: any, displayName: string, cineroomIds: any, citizenId: string, casOfficeIds: any, 
  strategyResult: string, topSns: string, id: string
) => {
  window.localStorage.setItem('nara.isLogin', 'true');
  window.localStorage.setItem('nara.accessToken', access_token);
  window.localStorage.setItem('nara.refreshToken', refresh_token);
  window.localStorage.setItem('nara.cineroomIds', JSON.stringify(cineroomIds));
  window.localStorage.setItem('nara.casOfficeIds', JSON.stringify(casOfficeIds));
  window.localStorage.setItem('nara.citizenUser', JSON.stringify(toCitizenUser(id, displayName, citizenId, '', cineroomIds)));
  window.localStorage.setItem('nara.topSns', topSns);
  window.sessionStorage.setItem('nara.isTop', 'true');
  window.sessionStorage.setItem('nara.strategyResult', strategyResult);
}

export const toAudience = (name: string) => {
  return {
    id: 'audienceId',
    name,
    usid: ''
  }
}

export const toActor = (id: string, name: string) => {
  return {
    id,
    name,
    usid: ''
  }
}

export const checkCitizenWithdrawal = async (citizenId: string, cineroomId: string) => {
  const profile = await findProfileByCitizenId(citizenId);
  if (profile.dormant) {
    return 'profile dormant';
  }
  const cineroom = await findCineroomById(cineroomId);
  if (!cineroom) {
    return 'NoSuchElementCineroom';
  }
  return 'success';
}

function aysnc() {
  throw new Error('Function not implemented.');
}

export const checkProxy = async () => {
  const res = await axios.get(secureFlowURL+'/check-proxy');
  return res.data;
}

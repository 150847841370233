import React, { createRef } from "react";
import "../../css/common.css";
import moment from "moment";
import { defaultListCard, makeDepotImageUrl } from "../../shared/ImagePathUtil";
import ImageSize from "../../shared/ImageSize";

interface Props {
  cardType: string;
  image: string;
  depotImage?: string;
  slideImage?: [];
  contentTitle: string;
  contentPath: string;
  contentAddress: string;
  contentThumText: string;
  contentLink: string;
  hashTag: [];
  cineroomName: string;
  onClickDetailModal: (modalType: string, categoryId: string) => void,
  id?: any;
  cid?: any;
  categoryId?: string;
  categoryPath?: string;
  themeDescription?: string;
  contentType?: string;
  cardAttribute?: [];
  registrationTime: any;
  activeCategoryTab?:any,
  listBannerObject?: {id: string , title:string, type: string ,seq: number}
  boxInfo: boolean  
}

interface State {
  swiperObject:any,
}

class CardView extends React.Component<Props, State> {
  titleRef = createRef<HTMLDivElement>();
  descriptionsRef = createRef<HTMLParagraphElement>();

  static defaultProps: any = {
    boxInfo: false
  };

  state: State = {
    swiperObject: {}
  };

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}

  //@ts-ignore
  state: State = {};

  renderCardView() {
    switch (this.props.cardType) {
      case "theme3":
        return this.renderFirstCardView();
      case "theme4":
        return this.renderSecondCardView();  
      case 'list':
        return this.renderListCard();
    }
  }

  // theme:3, sequence:2
  renderThemeThreeSeqTwo(){
    const { id } = this.props;
    return (
      <div onClick={() => this.props.onClickDetailModal("theme", id)}>
        <a className="recommend_popup_bt">
          <div className="img_box" style={{borderTopRightRadius: '20px', borderTopLeftRadius: '20px'}}>
            <img
              onContextMenu={(e) => (e.preventDefault())}
              style={{borderTopRightRadius: '24px', borderTopLeftRadius: '24px'}}
              src={`${this.props.image}/${ImageSize.it12}`}
              alt=""
            />
          </div>
          <div className="info">
            <h4>{this.props.contentTitle}</h4>
            <p>{moment(this.props.registrationTime).format("YYYY.MM.DD")}</p>
          </div>
        </a>
      </div>
    );
  }

  // theme:3, sequence:4
  renderThemeThreeSeqFour(){
    const { id } = this.props;
    return (
      <li onClick={() => this.props.onClickDetailModal("theme", id)}>
            <a>
              <div className="img_box">
                <img
                  onContextMenu={(e) => (e.preventDefault())}
                  className="layout-card-img"
                  src={`${this.props.image}/${ImageSize.it8}`}
                  alt=""
                />
              </div>
              <div className="info_box">
                <h4>{this.props.contentTitle}</h4>
                <p>{moment(this.props.registrationTime).format("YYYY.MM.DD")}</p>
              </div>
            </a>
      </li>
    );
  }

  renderFirstCardView() {
    const { listBannerObject } = this.props;
    if(listBannerObject?.seq === 2) {
      return (
        this.renderThemeThreeSeqTwo()
      );
    } else if(listBannerObject?.seq === 4 || listBannerObject?.seq === 5) {
      return (
        this.renderThemeThreeSeqFour()
      );
    }
  }

  renderSecondCardView() {
    const { id } = this.props;
    return (

    <li onClick={() => this.props.onClickDetailModal("theme", id)}>
          <a>
            <div className="img_box">
              <img
                onContextMenu={(e) => (e.preventDefault())}
                className="layout-card-img"
                src={`${this.props.image}/${ImageSize.it8}`}
                alt=""
              />
            </div>
            <div className="info_box">
              <h4>{this.props.contentTitle}</h4>
              <p>{moment(this.props.registrationTime).format("YYYY.MM.DD")}</p>
            </div>
          </a>
    </li>
    );
  }

  renderListCard() {
    const {cardAttribute} = this.props
    const imageCard = cardAttribute?.map((obj:any, i:any) => {

      if (obj.imageUrl) {

        const depotImageUrl = makeDepotImageUrl(obj.imageDepotUrl, ImageSize.it5)
        const depotUrl = `${depotImageUrl}`
        //it5 -> it12(치환)
        const newUrl = depotUrl.replace("it5", "it12");

        return (
            <div key={i} className="dt-list-img" style={{height: '120px'}}>
              <img src={`${newUrl}`} alt="" style={{cursor: 'pointer'}}
                onContextMenu={(e) => (e.preventDefault())}
                onError={(error) => {
                  error.currentTarget.src = defaultListCard();
                }}
              />
            </div>
        )
      } else {
        return null;
      }}
    );
    return (
      <div style={{position: 'relative'}}>
        <div className="dt-list-img">
        {imageCard}
        </div>
      </div>
    )
  }

  render() {
    return <>{this.renderCardView()}</>;
  }
}



export default CardView;

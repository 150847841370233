export const BYTES_PER_CHUNK = 1024 * 1024 * 50; // 50 MB

export const EXTENSION_WHITELIST = 'bmp|jpg|png|jpeg|gif|wmv|wav|wma|mp3|aac|mp4|avi|mkv|wmv|mov|xlsx|xls|xlsm|ppt|pptx|pdf|doc|docx|hwp|zip';

export function download(downloadPath: string, fileName: string) {
  const element = document.createElement('a');

  element.setAttribute('href', downloadPath);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  element.target = '_blank';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
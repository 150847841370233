import React, { ContextType, createRef } from "react";
import "../css/common.css";
import axios from "axios";
import { LangType } from "../model/type/LangType";
import { MarketingType } from "../model/type/MarketingType";
import { NextRouter, useRouter, withRouter } from "next/router";
import { Button, Chip } from "@mui/material";
import LoginModal from "./LoginModal";
import CommonContext from "../context/CommonContext";
import { expiredSession, topSessionclear } from "../conlab";
import { topLogin } from "../shared/Function/util/LoginUtil";
import Alert from "./Alert";
import { TopSns } from "../model/type/TopSnsType";
import CommonModal from "../conlab/CommonModal";
import Modal from "../conlab/CommonModalView";
import TopLoginModal from "../conlab/TopLoginModal";

interface Props {
  children: React.ReactNode;
  router: NextRouter
}

interface State {
  popularKeywords: {
    rank: number,
    keyword: string
  }[];
  inputSearchValue: string;
  selectedLanguage: string;
  selectedMarketingType: string;
  selectedsite: string;
  recentSearchKeywords: [string, string, string, string, string];
  isEmptyKeyword: boolean;
  isOpenMenuDrawer: boolean;
  isOpenToggle: boolean;
  isOpenSearchDrawer: boolean;
}

class Layout extends React.Component<Props, State> {
  static defaultProps: any = {};
  static contextType = CommonContext;

  context!: ContextType<typeof CommonContext>;
  localStorageForKeyword = "recentSearch";
  inputRef = createRef<HTMLInputElement>();
  gnbRef = createRef<HTMLDivElement>();
  searchPageRef = createRef<HTMLDivElement>();
  familySiteListRef = createRef<HTMLUListElement>();

  constructor(props: any) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.setSelectedSite = this.setSelectedSite.bind(this);
    this.inputRef = React.createRef();
    this.gnbRef = React.createRef();
    this.searchPageRef = React.createRef();
    this.familySiteListRef = React.createRef();
  }

  //@ts-ignore
  state: State = {
    popularKeywords: [],
    inputSearchValue: '',
    selectedLanguage: LangType.ko,
    selectedMarketingType: MarketingType.ALL,
    selectedsite: "패밀리사이트",
    recentSearchKeywords: ['', '', '', '', ''],
    isEmptyKeyword: false,
    isOpenMenuDrawer: false,
    isOpenToggle: false,
    isOpenSearchDrawer: false,
    ...this.props
  };

  componentDidMount() {
    const { isLogin, isOpenLoginModal } = this.context;
    this.findPopularKeyword();
    this.getRecentSearchKeywords();
    window.addEventListener("click", (event: any) => { this.closeToggleByWindow(event) });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
  }

  async findPopularKeyword() {
    // 인기 검색어
    await axios.get("/api/keyword/elastic/popular/find-keyword-list")
      .then((response) => {
        this.setState({ popularKeywords: response.data.list });
      }).catch((error) => {
        console.log(error);
      });
  }

  closeToggleByWindow(event: any) {
    const id = event.target.id
    if (id == 'family_site_bt') {
      return;
    }

    this.state.isOpenToggle = true;
    this.setToggleStateByWindow(this.state.isOpenToggle);
  }

  setToggleStateByWindow(value: boolean) {
    this.setState({ isOpenToggle: value }, () => {
      this.toggleSlide();
    });
  }

  searchKeyword() {
    const { inputSearchValue, selectedLanguage, selectedMarketingType } = this.state;
    if (inputSearchValue === '') {
      this.inputRef.current?.blur();
      this.setState({ isEmptyKeyword: true });
      return;
    }

    let searchUrl = "/rms-conlab/search?";

    searchUrl += "keyword=" + encodeURIComponent(inputSearchValue);

    if (selectedLanguage) {
      searchUrl += "&lang=" + encodeURIComponent(selectedLanguage);
    }

    if (selectedMarketingType) {
      searchUrl += "&marketingType=" + encodeURIComponent(selectedMarketingType);
    }

    this.saveRecentSearchKeyword(inputSearchValue);

    window.location.href = searchUrl;
  }

  searchPopularKeyword(keyword: string) {
    this.setState({ inputSearchValue: keyword }, () => {
      this.searchKeyword();
    });
  }

  handleInputSearchValue(value: string) {
    this.setState({ inputSearchValue: value });
  }

  setLanguageValue(value: string) {
    this.setState({ selectedLanguage: value });
  }

  setMarketingValue(value: string) {
    this.setState({ selectedMarketingType: value });
  }

  setSelectedSite(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    // @ts-ignore
    this.setState({ selectedsite: event.target.text });
  }

  refreshAll() {
    this.setState({
      inputSearchValue: '',
      selectedLanguage: LangType.ko,
      selectedMarketingType: MarketingType.ALL
    })
  }
  clearInputValue() {
    this.setState({ inputSearchValue: '' });
    this.inputRef.current?.focus();
  }

  saveRecentSearchKeyword(keyword: string) {
    const { recentSearchKeywords } = this.state;
    // --------
    let index = recentSearchKeywords.indexOf(keyword);

    if (index !== -1) {
      recentSearchKeywords.splice(index, 1);
      recentSearchKeywords.unshift(keyword);
    } else {
      recentSearchKeywords.unshift(keyword);
      recentSearchKeywords.pop();
    }
    // -------
    this.setState({ recentSearchKeywords: recentSearchKeywords });

    const arrString = JSON.stringify(recentSearchKeywords);
    window.localStorage.setItem(this.localStorageForKeyword, arrString);
  }

  getRecentSearchKeywords() {
    // @ts-ignore
    const recentSearchKeywords: [string, string, string, string, string] = JSON.parse(window.localStorage.getItem(this.localStorageForKeyword));
    if (recentSearchKeywords) {
      this.setState({ recentSearchKeywords: recentSearchKeywords });
    }
  }

  deleteRecentSearchKeyword(keyword: string) {
    const { recentSearchKeywords } = this.state;
    const index = recentSearchKeywords.indexOf(keyword);
    if (index !== -1) {
      recentSearchKeywords.splice(index, 1);
      recentSearchKeywords.push("");
      const arrString = JSON.stringify(recentSearchKeywords);
      window.localStorage.setItem(this.localStorageForKeyword, arrString);
      this.setState({ recentSearchKeywords: recentSearchKeywords });
    }
  }

  onClickLogin() {
    const { handleLoginState } = this.context;
    handleLoginState(true);
  }

  eventOpeningModal() {
    const { openLoginModal } = this.context;
    openLoginModal();
  }

  logOut() {
    topSessionclear();
    window.location.reload();
  }

  closeModal() {
    this.inputRef.current?.focus();
    this.setState({ isEmptyKeyword: false });
  }

  renderPopKeyword() {
    const { popularKeywords } = this.state;
    const popKeyword = popularKeywords?.map((obj: any, index: number) => {
      return (
        <button key={index} className="st_point"><strong style={{ fontSize: '16px' }} onClick={() => this.searchPopularKeyword(obj.keyword)}>{obj.keyword}</strong></button>
      );
    })

    return (
      <div className="list_box">
        {popKeyword}
      </div>
    )
  }

  fadeIn(el: any, display = "block") {
    el.style.opacity = 0;
    el.style.display = display || "block";
    (function fade() {
      let val = parseFloat(el.style.opacity);
      if (!((val += .1) > 1)) {
        el.style.opacity = val;
        requestAnimationFrame(fade);
      }
    })();
  }

  fadeOut(el: any) {
    el.style.opacity = 1;
    (function fade() {
      if ((el.style.opacity -= .1) < 0) {
        el.style.display = "none";
      } else {
        requestAnimationFrame(fade);
      }
    })();
  }

  handleMenuDrawer() {
    const { isOpenMenuDrawer } = this.state;
    const html = document.getElementById("html-root");
    const contents = document.getElementById("contents");

    if (isOpenMenuDrawer) {
      html?.classList.remove("sc_stop");
      contents?.classList.remove("blur");
      this.fadeOut(this.gnbRef.current);
      this.gnbRef.current?.classList.remove("active");
    } else {
      html?.classList.add("sc_stop");
      contents?.classList.add("blur");
      this.gnbRef.current!.style.display = "block"
      this.fadeIn(this.gnbRef.current);
      this.gnbRef.current?.classList.add("active");
    }

    this.setState({ isOpenMenuDrawer: !this.state.isOpenMenuDrawer });
  }

  handleSearchDrawer() {
    const { isOpenSearchDrawer } = this.state;

    const recent = window.localStorage.getItem(this.localStorageForKeyword);
    if (recent) {
      const recentSearchKeywords: [string, string, string, string, string] = JSON.parse(recent);
      this.setState({ recentSearchKeywords: recentSearchKeywords });
    }

    const html = document.getElementById("html-root");
    const contents = document.getElementById("contents");

    if (isOpenSearchDrawer) {
      html?.classList.remove("sc_stop");
      contents?.classList.remove("blur");
      this.fadeOut(this.searchPageRef.current);
      this.searchPageRef.current?.classList.remove("active");
    } else {
      html?.classList.add("sc_stop");
      contents?.classList.add("blur");
      this.searchPageRef.current!.style.display = "block"
      this.fadeIn(this.searchPageRef.current);
      this.searchPageRef.current?.classList.add("active");
      this.inputRef.current?.focus();
    }

    this.setState({ isOpenSearchDrawer: !this.state.isOpenSearchDrawer });

  }

  toggleSlide() {
    const { isOpenToggle } = this.state;
    const familySite = this.familySiteListRef.current;
    if (!familySite) {
      return;
    }
    if (isOpenToggle) {
      familySite!.style.display = "none";
    } else {
      familySite!.style.display = "block";
    }

    this.setState({ isOpenToggle: !this.state.isOpenToggle });

  }

  onClickBackground() {
    const { isOpenMenuDrawer, isOpenSearchDrawer } = this.state;
    if (isOpenMenuDrawer) {
      this.handleMenuDrawer();
    } else if (isOpenSearchDrawer) {
      this.handleSearchDrawer();
    }
  }

  renderProfile() {
    const { loginSnsKey } = this.context;
    switch (loginSnsKey) {
      case TopSns.APPLE:
        return (
          <img src={`/rms-conlab/images/login_${TopSns.APPLE}.png`} alt="마이페이지" onClick={() => this.eventOpeningModal()} />
        );
      case TopSns.FACEBOOK:
        return (
          <img src={`/rms-conlab/images/login_${TopSns.FACEBOOK}.png`} alt="마이페이지" onClick={() => this.eventOpeningModal()} />
        );
      case TopSns.GOOGLE:
        return (
          <img src={`/rms-conlab/images/login_${TopSns.GOOGLE}.png`} alt="마이페이지" onClick={() => this.eventOpeningModal()} />
        );
      case TopSns.KAKAO:
        return (
          <img src={`/rms-conlab/images/login_${TopSns.KAKAO}.png`} alt="마이페이지" onClick={() => this.eventOpeningModal()} />
        );
      case TopSns.NAVER:
        return (
          <img src={`/rms-conlab/images/login_${TopSns.NAVER}.png`} alt="마이페이지" onClick={() => this.eventOpeningModal()} />
        );
      default:
        const ktosso = window.sessionStorage.getItem('nara.isKtosso');
        const ktoSso = window.sessionStorage.getItem('nara.isKtoSso');
        if ((ktosso && ktosso === 'true') || (ktoSso && ktoSso === 'true')) {
          return (
            <img src={`/rms-conlab/images/login_${TopSns.KTO}.png`} alt="마이페이지" onClick={() => this.eventOpeningModal()} />
          );
        } else {
          return (
            <img src="/rms-conlab/images/icon_my.svg" alt="마이페이지" onClick={() => this.eventOpeningModal()} />
          );
        }
    }
  }

  render() {
    const { popularKeywords, inputSearchValue, selectedLanguage, selectedMarketingType, selectedsite, recentSearchKeywords, isEmptyKeyword } = this.state;
    const {
      isOpenLoginModal, isLogin, citizenUser, loginSnsKey, openModal, modalText,
      closeLoginModal, topLoginFail, loginFailText, open, closeTopLoginModal, onClickTopLoginModalOpen, openTopLoginModal,
      onClickTopLogin, closeLoginFailModal, topLoginCloseModal
    } = this.context;
    // @ts-ignore
    const isCheckedProfile = JSON.parse(window.localStorage.getItem("nara.isCheckedProfile")) || this.context.isCheckedProfile;

    return (
      <>
        <div className="layout-gnb">
          {/*<!-- 상단 -->*/}


          <header>
            <div className="center">
              <h1 className="logo" style={{ display: 'flex' }}>
                <a href="/rms-conlab">
                  <img src="/rms-conlab/images/logo.png" alt="한국관광콘텐츠랩" />
                </a>
                {/* <a href="https://visitkoreayear.kr/enu/index.kto" target="_blank" style={{marginLeft: 12}}>
                  <img src="/rms-conlab/images/img_bi_b.svg" alt="한국방문의 해" />
                </a> */}
              </h1>


              <div className="navbar">
                <div className="gnb">
                  <button className="sub-gnb">
                    <a href="/rms-conlab/introduce">소개</a> </button>
                  <div className="gnb-introduce">
                    <div>
                      <a href="/rms-conlab/introduce" style={{ marginRight: '5%' }}>한국관광콘텐츠랩이란?</a>
                      <a href="/rms-conlab/copyright-policy" >저작권 정책</a>
                      {/* <a href="/rms-conlab/guide" style={{ marginRight: '5%' }}>이용가이드</a> */}
                    </div>
                  </div>
                </div>

                <div className="gnb">
                  <button className="sub-gnb">
                    <a href="/rms-conlab/tourism-statistics">관광콘텐츠</a></button>
                  <div className="gnb-introduce">
                    <div>
                      <a href="/rms-conlab/tourism-statistics" style={{ marginRight: '5%' }}>관광콘텐츠 통계</a>
                      <a href="/rms-conlab/tourism-contents" style={{ marginRight: '5%' }}>관광콘텐츠 유형</a>
                      <a href="/rms-conlab/tourism-recommend" >추천 관광콘텐츠</a>
                    </div>
                  </div>
                </div>

                <div className="gnb">
                  <button className="sub-gnb">
                    <a href="/rms-conlab/online-service">활용사례</a></button>

                  {/* <div className="gnb-introduce">
                        <div>
                        <a href="/rms-conlab/online-service" style={{ marginRight: '5%' }}>온라인 활용</a>
                        <a href="/rms-conlab/offline-service" style={{ marginRight: '5%' }}>오프라인 활용</a>
                       </div>
                  </div> */}

                </div>

                <div className="gnb">
                  <button className="sub-gnb">
                    <a href="/rms-conlab/announcement">고객센터</a> </button>
                  <div className="gnb-introduce">
                    <div>
                      <a href="/rms-conlab/announcement" style={{ marginRight: '5%' }} >공지사항</a>
                      <a href="/rms-conlab/questions" >자주 묻는 질문</a>
                    </div>
                  </div>
                </div>
              </div>


              <div className="menu_bts">
                <button className="st_icon gnb_my_bt">
                  {isLogin ? this.renderProfile()
                    : <img src="/rms-conlab/images/icon_my.svg" alt="마이페이지" onClick={() => this.eventOpeningModal()} />
                  }

                </button>
                <button className="st_icon gnb_search_bt" onClick={() => this.handleSearchDrawer()}>
                  <img className="white" src="/rms-conlab/images/icon_search.svg" alt="검색" />
                </button>
                <button className="st_icon gnb_menu_bt" onClick={() => this.handleMenuDrawer()}>
                  <img src="/rms-conlab/images/icon_menu.svg" alt="메뉴" />
                </button>
              </div>
            </div>
          </header>
        </div>


        {/*<!-- 상단끝 -->*/}
        {/*<!-- 로그인 페이지 -->*/}
        {isOpenLoginModal && (<LoginModal />)}

        {/* 로그인 권한 확인 */}
        {openModal || topLoginFail
          ? <CommonModal
            confirmed={ modalText === expiredSession ? { btn1: '확인', btn2: undefined } : undefined}
            onClickCofirmedBtn={ modalText === expiredSession ? { btn1: this.context.closeModal, btn2: () => {} } : undefined}
            close={topLoginFail ? closeLoginFailModal : this.context.closeModal}
            message={topLoginFail ? loginFailText : modalText}
          />
          : null
        }

        {open && open === "open" && (
          <Modal close={closeTopLoginModal} login={onClickTopLoginModalOpen} />
        )}

        {openTopLoginModal && <TopLoginModal login={onClickTopLogin} topCloseModal={topLoginCloseModal} />}


        {/*<!-- 로그인 페이지 끝 -->*/}

        {/*<!-- 검색 페이지 -->*/}
        <div className="search_page" ref={this.searchPageRef}>
          <header>
            <div className="center">
              <h1 className="logo" style={{display: 'flex'}}>
                <a href="/rms-conlab">
                  <img
                    src="/rms-conlab/images/logo_trans.png"
                    alt="한국관광콘텐츠랩"
                  />
                </a>
              </h1>
              <div className="search_form">
                <button type="button" className="icon_search_option_bt">
                  <img src="/rms-conlab/images/icon_search_option.png" alt="icon" />
                </button>
                <label className="search_input_label" htmlFor="search_input">
                  <input
                    ref={this.inputRef}
                    type="text"
                    id="search_input"
                    placeholder="검색어를 입력하세요"
                    className="search_in"
                    value={inputSearchValue}
                    onChange={(event: any) => this.handleInputSearchValue(event.target.value)}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        this.searchKeyword();
                      }
                    }}
                  />
                  <button type="button" className="clear_bt" onClick={() => this.clearInputValue()}>
                    <img src="/rms-conlab/images/icon_search_clear.png" alt="icon" />
                  </button>
                </label>
                <button className="refresh_bt" onClick={() => this.refreshAll()}>
                  <img
                    src="/rms-conlab/images/icon_search_refresh.png"
                    alt="icon_search"
                  />
                </button>
                <button className="search_bt" onClick={() => this.searchKeyword()}>
                  <img src="/rms-conlab/images/icon_search.png" alt="icon_search" style={{ verticalAlign: 'inherit' }} />
                  검색
                </button>
              </div>
              <div className="menu_bts">
                <button className="st_point gnb_search_close_bt" onClick={() => this.handleSearchDrawer()}>
                  <img src="/rms-conlab/images/close_L.png" alt="메뉴" />
                </button>
              </div>
            </div>
          </header>
          <div className="search_page_body">
            <div className="center">
              <div className="word_wrap">
                <div className="hot">
                  <h3>인기 검색어</h3>
                  {this.renderPopKeyword()}
                </div>
                <div className="latest">
                  <h3>최근 검색어</h3>
                  <div className="list_box">
                    {recentSearchKeywords.map((obj: string, index: number) => {
                      if (!obj) {
                        return
                      }
                      return (
                        // TODO 퍼블리싱과 스타일 맞춰줄것
                        // <button className="st_line2" style={{fontSize: '16px'}} onClick={() => this.searchPopularKeyword(obj)}>
                        //   {obj}
                        //   <img src="./images/button_X.png" alt="button_X" style={{display: 'inline-flex', verticalAlign: 'baseline', padding: '0 0 0 8px'}}/>
                        // </button>
                        <Chip key={index} label={obj} variant="outlined" onDelete={() => this.deleteRecentSearchKeyword(obj)} onClick={() => this.searchPopularKeyword(obj)} />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="key_word">
                <div className="type">
                  <h4>유형별</h4>
                  <div className="list">
                    <button className={`st_basic ${selectedMarketingType === MarketingType.ALL ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.ALL)}>전체</button>
                    <button className={`st_basic ${selectedMarketingType === MarketingType.NA ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.NA)}>자연관광</button>
                    <button className={`st_basic ${selectedMarketingType === MarketingType.HS ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.HS)}>역사관광</button>
                    <button className={`st_basic ${selectedMarketingType === MarketingType.EX ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.EX)}>체험관광</button>
                    <button className={`st_basic ${selectedMarketingType === MarketingType.VE ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.VE)}>문화관광</button>
                    <button className={`st_basic ${selectedMarketingType === MarketingType.EV ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.EV)}>축제•공연•행사</button>
                    <button className={`st_basic ${selectedMarketingType === MarketingType.FD ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.FD)}>음식</button>
                    <button className={`st_basic ${selectedMarketingType === MarketingType.AC ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.AC)}>숙박</button>
                    <button className={`st_basic ${selectedMarketingType === MarketingType.SH ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.SH)}>쇼핑</button>
                    <button className={`st_basic ${selectedMarketingType === MarketingType.LS ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setMarketingValue(MarketingType.LS)}>레저스포츠</button>
                  </div>
                </div>
                <div className="language">
                  <h4>언어별</h4>
                  <div className="list">
                    <button className={`st_basic ${selectedLanguage === LangType.ALL ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.ALL)}>전체</button>
                    <button className={`st_basic ${selectedLanguage === LangType.ko ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.ko)}>한국어</button>
                    <button className={`st_basic ${selectedLanguage === LangType.en ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.en)}>영어</button>
                    <button className={`st_basic ${selectedLanguage === LangType.ja ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.ja)}>일본어</button>
                    <button className={`st_basic ${selectedLanguage === LangType.zh_CN ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.zh_CN)}>중국어 간체</button>
                    <button className={`st_basic ${selectedLanguage === LangType.zh_TW ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.zh_TW)}>중국어 번체</button>
                    <button className={`st_basic ${selectedLanguage === LangType.de ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.de)}>독일어</button>
                    <button className={`st_basic ${selectedLanguage === LangType.ru ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.ru)}>러시아어</button>
                    <button className={`st_basic ${selectedLanguage === LangType.fr ? 'on' : undefined}`} style={{ fontSize: '16px' }} onClick={() => this.setLanguageValue(LangType.fr)}>프랑스어</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg" onClick={() => this.onClickBackground()}></div>
        </div>
        {/*<!-- 검색 페이지 끝 -->*/}

        {/*<!-- 메뉴 페이지 -->*/}
        <div className="gnb_menu_page" ref={this.gnbRef} style={{ display: 'none' }}>
          <header>
            <div className="center">
              <h1 className="logo" style={{ display: 'flex' }}>
                <a href="/rms-conlab">
                  <img src="/rms-conlab/images/logo.png" alt="한국관광콘텐츠랩" />
                </a>
                {/* <a href="https://visitkoreayear.kr/enu/index.kto" target="_blank" style={{marginLeft: 12}}>
                  <img src="/rms-conlab/images/img_bi_b.svg" alt="한국방문의 해" />
                </a> */}
              </h1>
              <div className="menu_bts">
                <button className="st_icon gnb_menu_close_bt" onClick={() => this.handleMenuDrawer()}>
                  <img src="/rms-conlab/images/icon_menu_close.svg" alt="메뉴" />
                </button>
              </div>
            </div>
          </header>
          <div className="menu_body">
            <div className="center">
              <ul className="menu_list">
                <li>
                  <h3>
                    <a href="/rms-conlab/introduce" style={{ width: '51px', textAlign: 'center' }}>소개</a>
                  </h3>
                  <div className="sub_menu_wrap">
                    <div className="sub_menu">
                      <a href="/rms-conlab/introduce">
                        한국관광콘텐츠랩이란?
                      </a>
                    </div>
                    <div className="sub_menu">
                      <a href="/rms-conlab/copyright-policy">
                        저작권 정책
                      </a>
                    </div>
                    {/* <div className="sub_menu">
                      <a href="/rms-conlab/guide">이용가이드</a>
                    </div> */}
                  </div>
                </li>
                <li>
                  <h3>
                    <a href="/rms-conlab/tourism-contents" style={{ width: '110px' }}>관광콘텐츠</a>
                  </h3>
                  <div className="sub_menu_wrap">
                    <div className="sub_menu">
                      <a href="/rms-conlab/tourism-statistics">관광콘텐츠 통계</a>
                    </div>
                    <div className="sub_menu">
                      <a href="/rms-conlab/tourism-contents">관광콘텐츠 유형</a>
                    </div>
                    <div className="sub_menu">
                      <a href="/rms-conlab/tourism-recommend">추천 관광콘텐츠</a>
                    </div>
                  </div>
                </li>
                <li>
                  <h3>
                    <a href="/rms-conlab/online-service">활용사례</a>
                  </h3>
                  {/* <div className="sub_menu_wrap">
                    <div className="sub_menu">
                      <a href="/rms-conlab/online-service">온라인 활용</a>
                    </div>
                    <div className="sub_menu">
                      <a href="/rms-conlab/offline-service">오프라인 활용</a>
                    </div>
                  </div> */}
                </li>
                <li>
                  <h3>
                    <a href="/rms-conlab/announcement">고객센터</a>
                  </h3>
                  <div className="sub_menu_wrap">
                    <div className="sub_menu">
                      <a href="/rms-conlab/announcement">공지사항</a>
                    </div>
                    <div className="sub_menu">
                      <a href="/rms-conlab/questions">자주 묻는 질문</a>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="menu_login_box">
                {isLogin && isCheckedProfile ? (
                  <h2>
                    안녕하세요
                    <br />
                    {citizenUser.displayName} 님
                  </h2>) : (
                  <h2>
                    로그인
                    <br />
                    해주세요
                  </h2>
                )}
                <div className="login_bts">
                  {isLogin ? (
                    <button className="login_bt st_line ok" style={{ marginTop: 16 }} onClick={() => this.logOut()}>로그아웃</button>
                  ) : (
                    <button
                      className="combined_login_bt"
                      onClick={() => topLogin()}
                    >
                      간편로그인
                      <img
                        src="/rms-conlab/images/Tour_Onepass.png"
                        alt="Tour_Onepass"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bg" onClick={() => this.onClickBackground()}></div>
        </div>
        {isEmptyKeyword && <Alert message="키워드를 입력해주세요." eventCloseModal={this.closeModal} />}
        {/*<!-- 메뉴 페이지 끝 -->*/}
        {this.props.children}

        {/*<!-- footer START -->*/}
        <footer style={{ paddingBottom: '30px' }}>
          <div className="center">
            <div className="ft_top">
              <div className="ft_links" style={{ display: 'flex' }}>
                <a href="/rms-conlab/terms-of-use" target="_blank">이용약관</a>
                <a href="https://knto.or.kr/helpdeskPolicyPrivacyHomepage" target="_blank">개인정보처리방침</a>
                <a href="/rms-conlab/terms-of-email" target="_blank">전자우편무단수집거부</a>
              </div>
              <div className="family_site_box">
                <ul className="family_site_list" ref={this.familySiteListRef}>
                  <li>
                    <a href="https://knto.or.kr/index" onClick={(event) => this.setSelectedSite(event)} id="한국관광공사" target="_blank" >한국관광공사</a>
                  </li>
                  <li>
                    <a href="https://api.visitkorea.or.kr" onClick={(event) => this.setSelectedSite(event)} target="_blank" id="TourAPI">Tour API</a>
                  </li>
                  <li>
                    <a href="https://korean.visitkorea.or.kr/main/main.do#home" onClick={(event) => this.setSelectedSite(event)} target="_blank" id="대한민국구석구석">대한민국 구석구석</a>
                  </li>
                  <li>
                    <a href="https://touraz.kr/index" onClick={(event) => this.setSelectedSite(event)} target="_blank" id="투어라즈">투어라즈</a>
                  </li>
                  <li>
                    <a href="https://datalab.visitkorea.or.kr/datalab/portal/main/getMainForm.do" onClick={(event) => this.setSelectedSite(event)} target="_blank" id="한국관광데이터랩" >한국관광데이터랩</a>
                  </li>
                  <li>
                    <a href="https://visitkoreayear.kr/enu/index.kto" onClick={(event) => this.setSelectedSite(event)} target="_blank" id="한국방문의 해" >한국방문의 해</a>
                  </li>
                </ul>
                <button id="family_site_bt" className="family_site_bt st_line2" onClick={() => this.toggleSlide()}>
                  {this.state.selectedsite}
                  <img src="/rms-conlab/images/icon_Move_newtab_S.svg" alt="icon" />
                </button>
              </div>
            </div>
            <div className="ft_bottom">
              <div className="ft_info">
                <p>
                  한국관광공사 26464 강원특별자치도 원주시 세계로 10
                  한국관광공사 | <span>TEL : 033-738-3000 </span>
                  <br/>
                  <span>사업자등록번호 : 202-81-50707 | </span>
                  <span>&nbsp;통신판매업신고 : 제2022-강원원주-0381호</span>
                </p>
                <p>Copyright © 한국관광공사. All rights reserved.</p>
              </div>
              <h1 className="ft_logo">
                <img src="/rms-conlab/images/knto_logo.svg" alt="한국관광공사" />
                <img src="/rms-conlab/images/img_bi_b.svg" alt="한국방문의 해" style={{ marginTop: 11, marginLeft: 15, cursor: 'pointer' }} onClick={() => { window.open("https://visitkoreayear.kr/enu/index.kto") }} />
              </h1>
            </div>
          </div>
        </footer>
        {/*<!-- footer END -->*/}
      </>
    );
  }

}

export default Layout;
